import React from 'react'
import LiveData from '../../liveData/LiveData'
import useLiveData from '../../liveData/useLiveData'
import ActionData from '../../plainTypes/ActionData'
import { contains } from '../../utils/array'
import ErrorToast, { ToastDuration } from '../ErrorToast'

export interface LiveDataErrorToastProps {
  liveData: LiveData<ActionData<Error> | undefined>
  tags?: (string | undefined)[]
  duration?: ToastDuration
}

const LiveDataErrorToast: React.FC<LiveDataErrorToastProps> = ({
  liveData, tags, duration,
}: LiveDataErrorToastProps) => {
  const [error, setError] = useLiveData(liveData)

  return (
    <ErrorToast
      isOpen={!!error && (!tags || contains(tags, error.tag))}
      message={error?.value?.message ?? ''}
      duration={duration ?? ToastDuration.SHORT}
      onDidDismiss={() => setError(undefined)}
    />
  )
}

export default LiveDataErrorToast
