import ReactLocalizedStrings, { LocalizedStringsMethods } from 'react-localization'
import { injectable } from 'inversify'
import Localization from './Localization'
import StringsTypes from './StringsTypes'

// translations
import LocalizedStrings from './translations/default-pt'
import EnglishStrings from './translations/en'

@injectable()
export default class ReactLocalization implements Localization {
  readonly strings: StringsTypes

  constructor() {
    this.strings = new ReactLocalizedStrings({
      en: new EnglishStrings(),
      pt: new LocalizedStrings(),
    })
  }

  format = (str: string, ...args: any[]) => (this.strings as any as LocalizedStringsMethods)
    .formatString(str, ...args)
    .toString()

  formatLineBreaks = (str: string) => str.replace(/\n/g, '<br />')

  joinLines = (lines: string[]) => lines.join('<br />')
}
