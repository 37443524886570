import { IonAlert } from '@ionic/react'
import React from 'react'
import SMSValidator, { SMSValidatorDialogState } from '../../controllers/smsValidator/SMSValidator'
import AppErrorCode from '../../errors/AppErrorCode'
import { bindReset } from '../../liveData/bindings'
import useLiveData from '../../liveData/useLiveData'
import useLocalization from '../../plugins/localization/useLocalization'
import formatPhone from '../../utils/formatting/phone'
import './SMSValidatorView.scss'

export type SMSValidatorViewProps = {
  validator: SMSValidator
}

const SMSValidatorView: React.FC<SMSValidatorViewProps> = ({
  validator,
}: SMSValidatorViewProps) => {
  const { strings, formatLineBreaks } = useLocalization()

  const [dialogState] = useLiveData(validator.dialogState)
  const phone = formatPhone(useLiveData(validator.phone)[0], true)
  const [code] = useLiveData(validator.code)

  return (
    <>
      <IonAlert
        isOpen={dialogState === SMSValidatorDialogState.PHONE_CONFIRMATION}
        onDidDismiss={bindReset(validator.dialogState, SMSValidatorDialogState.PHONE_CONFIRMATION)}
        header={strings.attention}
        message={strings.template_dialog_message_sms_phone(
          `<div class="sms-validator__confirm-modal__phone">${phone}</div>`,
        )}
        buttons={[strings.alter, { text: strings.confirm, handler: validator.sendCode }]}
      />
      <IonAlert
        cssClass="sms-validator__code-modal"
        isOpen={dialogState === SMSValidatorDialogState.CODE}
        backdropDismiss={false}
        header={strings.template_dialog_title_sms_code(phone)}
        message={strings.dialog_message_sms_code}
        inputs={[{ type: 'number', value: code }]}
        buttons={[
          {
            text: strings.dialog_button_sms_code,
            // lbaglie: yes, the animals at ionic really provide an object with
            // int keys, instead of a fucking array
            // and yes, this is the only fucking way to get this fucking value
            handler: ({ 0: value }) => {
              validator.code.set(value)
              validator.validateCode()
            },
            cssClass: 'sms-validator__code-modal__validate',
          },
          strings.cancel,
          {
            text: strings.dialog_button_sms_resend,
            handler: validator.resendCode,
            cssClass: 'sms-validator__code-modal__resend',
          },
        ]}
      />
      <IonAlert
        isOpen={dialogState === SMSValidatorDialogState.SUPPORT}
        backdropDismiss={false}
        onWillDismiss={validator.cancelSupport}
        header={strings.error_app_code_option(AppErrorCode.SMS_LIMIT_REACHED)}
        buttons={[
          strings.dialog_button_sms_support_wait,
          {
            text: strings.dialog_button_sms_support_request,
            handler: validator.acceptSupport,
          },
        ]}
      />
      <IonAlert
        isOpen={dialogState === SMSValidatorDialogState.INVALID_CODE}
        backdropDismiss={false}
        onDidDismiss={bindReset(validator.dialogState, SMSValidatorDialogState.INVALID_CODE)}
        header={strings.attention}
        message={strings.dialog_message_sms_invalid}
        buttons={[{ text: strings.back, handler: validator.retryInputCode }]}
      />
      <IonAlert
        isOpen={dialogState === SMSValidatorDialogState.FINISHED}
        backdropDismiss={false}
        onDidDismiss={bindReset(validator.dialogState, SMSValidatorDialogState.INVALID_CODE)}
        header={strings.dialog_title_sms_valid}
        message={formatLineBreaks(strings.dialog_message_sms_valid)}
        buttons={[{ text: strings.ok, handler: validator.finishValidation }]}
      />
    </>
  )
}

export default SMSValidatorView
