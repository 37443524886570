import React, { useEffect, useState } from 'react'
import LiveData from './LiveData'

export default function useLiveData<T>(
  data: LiveData<T>,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState(data.value)
  useEffect(() => {
    const disposable = data.observe(setValue)
    return disposable.dispose
  }, [data])
  return [value, setValue]
}
