enum ContactorType {
  HOLDER = 'HOLDER',
  AUTHORITY = 'AUTHORITY',
  CONSUMER_PROTECTION_AGENCY = 'CONSUMER_PROTECTION_AGENCY'
}

export default ContactorType

export const ContactorTypeEx = {
  allValues: Object.values(ContactorType),
}
