import InjectableType from '../dependencyInjection/InjectableType'

export type NavigatorOptions = {
  replaces?: boolean
  fromPath?: string
  useLocation?: boolean
}

interface Navigator {
  back(): void
  close(): void
  support(subject: string, body: string): void
  getQuery<T>(): T
  isIn(path: string): boolean
}

const Navigator: InjectableType = {
  SYMBOL: Symbol.for('Navigator'),
}

export default Navigator
