
import { inject, injectable } from 'inversify'
import { isNil, last, some } from 'lodash'
import BoolSelectionControllerImpl from '../../controllers/selection/BoolSelectionControllerImpl'
import SelectionControllerImpl from '../../controllers/selection/SelectionControllerImpl'
import SMSValidatorImpl from '../../controllers/smsValidator/SMSValidatorImpl'
import AppErrorCode from '../../errors/AppErrorCode'
import LazyLiveDataImpl from '../../liveData/LazyLiveDataImpl'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import Navigator from '../../navigation/Navigator'
import ContactorType, { ContactorTypeEx } from '../../plainTypes/dpo/ContactorType'
import { FileFormatEx } from '../../plainTypes/FileFormat'
import { LiveFormDataEx } from '../../plainTypes/FormData'
import SMSType from '../../plainTypes/phone/SMSType'
import Reason from '../../plainTypes/Reason'
import DPORepository, { PHONE_LENGTHS } from '../../repositories/dpo/DPORepository'
import PhoneRepository from '../../repositories/phone/PhoneRepository'
import CompositeDisposable from '../../utils/disposasble/CompositeDisposable'
import Disposable, { DisposableEx } from '../../utils/disposasble/Disposable'
import { convertFileSize } from '../../utils/number'
import AsyncViewModelImpl from '../AsyncViewModelImpl'
import DPOViewModel, { DPODialogState } from './DPOViewModel'

@injectable()
export default class DPOViewModelImpl extends AsyncViewModelImpl
  implements DPOViewModel {
    @inject(DPORepository.SYMBOL)
    private readonly repository: DPORepository

    @inject(PhoneRepository.SYMBOL)
    private readonly phoneRepository: PhoneRepository

    @inject(Navigator.SYMBOL)
    private readonly navigator: Navigator

    private prepareValidator = <TError>(
      validator: (value: string) => TError | undefined,
      errorTextOptions: (err: TError) => string,
    ) => (data: string) => {
      const error = validator(data)
      return error === undefined ? '' : errorTextOptions(error)
    }

    readonly dialogState = new LiveDataImpl(DPODialogState.NONE)

    readonly contactorTypeController = new SelectionControllerImpl<ContactorType>()

    readonly membershipController = new BoolSelectionControllerImpl()

    readonly reasonController = new SelectionControllerImpl<Reason>()

    readonly name = new LazyLiveDataImpl(() => LiveFormDataEx.new(
      '',
      this.prepareValidator(
        this.repository.validateName,
        this.localization.strings.options_error_text,
      ),
    ))

    readonly email = new LazyLiveDataImpl(() => LiveFormDataEx.new(
      '',
      this.prepareValidator(
        this.repository.validateEmail,
        this.localization.strings.options_error_email,
      ),
    ))

    readonly phone = new LazyLiveDataImpl(() => LiveFormDataEx.new(
      this.localization.strings.default_country_code,
      this.prepareValidator(
        this.repository.validatePhone,
        this.localization.strings.options_error_phone,
      ),
      (value) => `+${value.replace(/[^\d]/g, '')}`.substr(0, last(PHONE_LENGTHS)),
    ))

    readonly message = new LazyLiveDataImpl(() => LiveFormDataEx.new(
      '',
      this.prepareValidator(
        this.repository.validateMessage,
        this.localization.strings.options_error_text,
      ),
    ))

    readonly attachment = new LazyLiveDataImpl(() => LiveFormDataEx.new<File | undefined>(
      undefined,
      (data) => {
        if (!data) {
          return ''
        }

        if (data.size > DPOViewModel.ATTACHMENT_MAX_SIZE) {
          return this.localization.strings.template_error_field_file_maxsize(
            convertFileSize(
              DPOViewModel.ATTACHMENT_MAX_SIZE, 'bytes', 'mb',
            ),
            'mb',
          )
        }

        if (!some(DPOViewModel.ATTACHMENT_ALLOWED_FORMATS, (f) => FileFormatEx.is(f, data.type))) {
          return this.localization.strings.template_error_field_file_formats(
            DPOViewModel.ATTACHMENT_ALLOWED_FORMATS,
          )
        }

        return ''
      },
    ))

    readonly sendButtonEnabled = new LiveDataImpl(false)

    readonly smsValidator = new SMSValidatorImpl(
      (phone) => this.performAsync(
        this.phoneRepository.sendSMS(phone, SMSType.DPO_AUTHENTICATION),
        DPOViewModel.TAG_SEND_SMS,
        (err) => err.code === AppErrorCode.SMS_LIMIT_REACHED,
      ),
      (phone, code) => this.performAsync(
        this.phoneRepository.checkSMSCode(phone, code, SMSType.DPO_AUTHENTICATION),
        DPOViewModel.TAG_VALIDATE_CODE,
      ),
    )

    private disposable: Disposable

    init = (currentLanguage: string) => this.performAsyncSafe(
      async () => {
        const reasons: Reason[] = await this.repository.getDPOReasons(currentLanguage)
        this.reasonController.init(reasons)

        this.smsValidator.init(this.navigator, this.localization)

        const {
          contactorType, active, email, phone,
        } = this.navigator.getQuery<{
          contactorType?: ContactorType
          active?: string
          email?: string
          phone?: string
        }>() ?? {}

        this.contactorTypeController.init(ContactorTypeEx.allValues)
        if (contactorType) {
          this.contactorTypeController.selectItem(contactorType)
        }

        this.membershipController.init()
        if (!isNil(active)) {
          this.membershipController.selectItem(active === true.toString())
        }

        if (email) {
          LiveFormDataEx.setData(this.email, email)
        }

        if (phone) {
          LiveFormDataEx.setData(this.phone, phone)
        }

        this.disposable = new CompositeDisposable(
          this.name.observe(this.setSendButtonEnabled),
          this.email.observe(this.setSendButtonEnabled),
          this.phone.observe(this.setSendButtonEnabled),
          this.message.observe(this.setSendButtonEnabled),
          this.attachment.observe(this.setSendButtonEnabled),
          this.contactorTypeController.selectedItem.observe(this.setSendButtonEnabled),
          this.membershipController.selectedItem.observe(this.setSendButtonEnabled),
          this.reasonController.selectedItem.observe(this.setSendButtonEnabled),
          this.smsValidator.validatedTrigger.observe(this.doSend),
        )
      },
      DPOViewModel.TAG_INIT,
    )

    private setSendButtonEnabled = () => {
      const areValuesOk = [
        this.name, this.email, this.phone, this.message,
      ].reduce((agg, err) => err.value.error === '' && agg, true as boolean)
      this.sendButtonEnabled.set(
        areValuesOk &&
        !this.attachment.value.error &&
        this.contactorTypeController.selectedItem.hasValue &&
        this.reasonController.selectedItem.hasValue &&
        this.membershipController.selectedItem.hasValue,
      )
    }

    deinit = () => {
      DisposableEx.safeDispose(this.disposable)
      this.smsValidator.deinit()
      this.contactorTypeController.deinit()
      this.membershipController.deinit()
      this.reasonController.deinit()
      this.deinitFun()
    }

    close = () => {
      this.navigator.close()
    }

    send = () => {
      if (!this.sendButtonEnabled.value) {
        return
      }

      this.smsValidator.startValidation(this.phone.value.data)
    }

    private doSend = () => this.performAsyncSafe(
      this.repository.requestDPO(
        this.contactorTypeController.selectedItem.value!,
        this.name.value.data,
        this.email.value.data,
        this.membershipController.selectedItem.value!,
        this.phone.value.data,
        this.reasonController.selectedItem.value!,
        this.message.value.data,
        this.attachment.value.data,
      )
        .then(() => this.dialogState.set(DPODialogState.SENT)),
      DPOViewModel.TAG_SEND,
    )

    finish = this.close
}
