/* eslint-disable lines-between-class-members */
/* eslint-disable camelcase */

import AppErrorCode from '../../../errors/AppErrorCode'
import ContactorType from '../../../plainTypes/dpo/ContactorType'
import FileFormat, { FileFormatEx } from '../../../plainTypes/FileFormat'
import { EmailError, PhoneError, TextError } from '../../../repositories/dpo/DPORepository'
import { translateOptions, translateTemplate } from './utils'

export default class EnglishStrings {
  // common
  app_name = 'ysos'
  ok = 'OK'
  yes = 'Yes'
  no = 'No'
  save = 'Save'
  cancel = 'Cancel'
  close = 'Close'
  signup = 'Cadastrar'
  enter = 'Enter'
  continue = 'Send'
  attention = 'Attention'
  confirm = 'Confirm'
  alter = 'Alter'
  back = 'Back'
  you = 'you'
  understood = 'Understood'
  format_date = 'DD/MM/YYYY'
  sexualorientation = 'Sexual Orientation'
  password = 'Password'
  dismiss = 'Dismiss'
  select = 'Select'
  delete = 'Delete'
  send = 'Send'
  default_country_code = '+1'
  mask_phone = '+1 999 9999 9999'
  template_field_file_maxsize = (size: number, unit: 'mb' = 'mb') => `Max size. ${size}${unit}.`
  template_field_file_formats = (formats: FileFormat[]) => `Supported files ${
    formats.map(FileFormatEx.fileExtension).join(' ')
  }`
  placeholder_field_file = 'Tap to select a file'

  // enums
  options_boolean_yesno = translateOptions(
    [true, 'Yes'],
    [false, 'No'],
  )
  options_contactor_type = translateOptions(
    [ContactorType.AUTHORITY, 'Authority'],
    [ContactorType.CONSUMER_PROTECTION_AGENCY, 'Consumer Protection Agency'],
    [ContactorType.HOLDER, 'Holder'],
  )

  // dpo
  title_dpo = 'Request Data'
  message_dpo_reasons = 'Fill out this form to send a request to the data protection officer under the General Data Protection Law (“Lei Geral de Proteção da Dados - LGPD”).'
  label_dpo_form_contactor = 'I am*'
  label_dpo_form_name = 'Name*'
  label_dpo_form_email = 'Email*'
  label_dpo_form_active = 'Are you active on the platform?*'
  label_dpo_form_phone = 'Phone Number*'
  label_dpo_form_reason = 'What is the subject of the application?*'
  label_dpo_form_message = 'Describe your request in detail*'
  label_dpo_form_attachment = 'Attachments (optional)'
  dialog_title_dpo_sent = 'Message sent!'
  dialog_message_dpo_sent = 'Our DPO will contact you shortly via email.'
  options_error_text = translateOptions(
    [TextError.EMPTY, 'Empty text'],
    [TextError.TOO_SHORT, 'Text too short'],
  )
  options_error_phone = translateOptions(
    [PhoneError.EMPTY, 'Empty phone'],
    [PhoneError.INVALID, 'Invalid phone'],
  )
  options_error_email = translateOptions(
    [EmailError.EMPTY, 'Empty email'],
    [EmailError.INVALID, 'Invalid email'],
  )
  template_dialog_message_sms_phone = translateTemplate('Is your mobile number spelled correctly?{0}')
  template_dialog_title_sms_code = translateTemplate(
    'Enter the code sent by SMS to {0}',
  )
  dialog_message_sms_code = 'Access your mobile message box and enter the code sent'
  dialog_button_sms_code = 'Validate Code'
  dialog_button_sms_resend = 'Resend Code'
  dialog_message_sms_invalid = 'Incorrect or expired code entered'
  dialog_title_sms_valid = 'Done'
  dialog_message_sms_valid = 'Your number has been verified.\nNow the request will be sent.'
  dialog_button_sms_support_wait = 'Wait a little longer'
  dialog_button_sms_support_request = 'Contact us'

  // support
  subject_support_sms = '[ysos app] I do not receive the registration SMS'
  template_body_support_sms = translateTemplate('My phone number is: {0}')

  // error
  error_generic = 'Oops! An error has occurred.\nPlease try again or contact support.'
  template_error_field_file_maxsize = (size: number, unit: 'mb' = 'mb') => `The attachment exceeds the ${size}${unit} limit. Reduce the file size.`
  template_error_field_file_formats = (formats: FileFormat[]) => `Attachment format not allowed. Use only ${
    formats.map(FileFormatEx.fileExtension).join(' ')
  }`
  error_app_code_option = translateOptions([
    [AppErrorCode.APP_INVALID_CREDENTIALS, 'Invalid login or password. Please try again!'],
    [AppErrorCode.APP_PERMISSION, 'The app does not have permission to perform the action.'],
    [AppErrorCode.APP_NO_ACCOUNT, 'You need to set up an email account to send emails to ysos.'],
    [AppErrorCode.APP_TIMEOUT, 'Timeout exceeded. Check your internet connection.'],
    [AppErrorCode.APP_PERMISSION_DENIED_CAMERA, 'You need to grant permission for the camera in the Settings app.'],
    [AppErrorCode.APP_COULDNT_CONNECT_STORE, 'Purchase cancelled.'],
    [AppErrorCode.APP_COULDNT_CONNECT_STORE_RESTORE, 'Restoration cancelled.'],
    [AppErrorCode.CODE_TWILIO_INVALID_PHONE_NUMBER, ''],
    [AppErrorCode.CODE_TWILIO_UNVERIFIED_PHONE_NUMBER, ''],
    [AppErrorCode.OBJECT_NOT_FOUND, 'Object not found.'],
    [AppErrorCode.USERNAME_MISSING, 'The profile name was not filled out! Please try again or contact support.'],
    [AppErrorCode.PASSWORD_MISSING, 'The password was not filled out! Please try again or contact support.'],
    [AppErrorCode.USERNAME_TAKEN, 'There is already a registration with this profile name! Please go back to the profile name registration and choose another one :('],
    [AppErrorCode.SESSION_MISSING, ''],
    [AppErrorCode.INVALID_USER, ''],
    [AppErrorCode.CODE_PHONE_ALREADY_REGISTERED, 'Oops! Number already registered.'],
    [AppErrorCode.NOT_ALLOWED_TO_SEND_SIGNUP_SMS, 'Oops! Your code has already been requested. It may take a few hours to receive the SMS.'],
    [AppErrorCode.CODE_PHONE_NOT_REGISTERED, 'Oops! Your number is not registered.'],
    [AppErrorCode.INVALID_SMS_VERIFICATION_CODE, 'Incorrect or expired code :('],
    [AppErrorCode.NOT_ALLOWED_TO_SEND_FORGOT_PASSWORD_SMS, 'Oops! Your code has already been requested. It may take a few hours to receive the SMS.'],
    [AppErrorCode.PHONE_BLACKLISTED, ''],
    [AppErrorCode.USER_IS_NOT_BLOCKED, ''],
    [AppErrorCode.USER_IS_BLOCKED, 'The user is no longer available.'],
    [AppErrorCode.NO_CHAT_OPEN_FOR_IDS, ''],
    [AppErrorCode.NO_CHAT_USER_IDS, ''],
    [AppErrorCode.CANT_BLOCK_YSOS_USER, 'Cannot block ysos user.'],
    [AppErrorCode.FAILED_TO_CONNECT, 'Could not connect to ysos. Please try again later.'],
    [AppErrorCode.PROFILE_ALREADY_FILLED, 'The profile is already filled out'],
    [AppErrorCode.INVALID_PARAMETERS, 'An internal error occurred. Contact support.'],
    [AppErrorCode.NO_LIKE_FOUND, 'The like was not found.'],
    [AppErrorCode.DEPLETED_LIKES, 'Your daily likes have ended. Wait 24 hours to continue liking.'],
    [AppErrorCode.SUBSCRIPTION_EXPIRED, 'No subscriptions are currently available.'],
    [AppErrorCode.ASSINET_INTERNAL_ERROR, 'Processing failed. Please contact support.'],
    [AppErrorCode.SMS_LIMIT_REACHED, 'Having trouble receiving the SMS?\nContact support.'],
    [AppErrorCode.ACCESS_DENIED, 'Your profile has been temporarily blocked! Please contact support for more information.'],
    [AppErrorCode.ATTACHMENT_FORMAT_UNALLOWED, 'Attachment format not allowed. Only .png, .jpg, .pdf, .doc and .docx files are accepted.'],
    [AppErrorCode.ATTACHMENT_SIZE_LIMIT, 'The file exceeded the limit. Reduce the file size.'],
  ], 'Oops! An error has occurred.\nPlease try again or contact support.')
}
