export function toInt(value: boolean | undefined | null): number {
  return value ? 1 : 0
}

export function leadingZeros(value: number, length: number): string {
  return value.toString().padStart(length, '0')
}

export function convertFileSize(
  size: number,
  from: 'mb' | 'bytes',
  to: 'mb' | 'bytes',
): number {
  if (from === to) {
    return size
  }

  return from === 'bytes'
    ? size / 1024 / 1024
    : size * 1024 * 1024
}
