import SelectionController from '../../controllers/selection/SelectionController'
import SMSValidator from '../../controllers/smsValidator/SMSValidator'
import LiveData from '../../liveData/LiveData'
import ContactorType from '../../plainTypes/dpo/ContactorType'
import { FileFormatEx } from '../../plainTypes/FileFormat'
import { FormData } from '../../plainTypes/FormData'
import Reason from '../../plainTypes/Reason'
import AsyncViewModel from '../AsyncViewModel'

export enum DPODialogState {
  NONE,
  SENT
}

interface DPOViewModel extends AsyncViewModel {
  readonly dialogState: LiveData<DPODialogState>

  readonly contactorTypeController: SelectionController<ContactorType>
  readonly membershipController: SelectionController<boolean>
  readonly reasonController: SelectionController<Reason>

  readonly name: LiveData<FormData<string>>
  readonly email: LiveData<FormData<string>>
  readonly phone: LiveData<FormData<string>>
  readonly message: LiveData<FormData<string>>
  readonly attachment: LiveData<FormData<File | undefined>>

  readonly sendButtonEnabled: LiveData<boolean>

  readonly smsValidator: SMSValidator

  init(currentLanguage: string): void
  close(): void
  send(): void
  finish(): void
}

const DPOViewModel = {
  SYMBOL: Symbol.for('DPOViewModel'),
  ATTACHMENT_MAX_SIZE: 1024 * 1024 * 2, // 2mb
  ATTACHMENT_ALLOWED_FORMATS: [
    FileFormatEx.fromSimpleMime('image/png'),
    FileFormatEx.fromSimpleMime('image/jpg'),
    FileFormatEx.fromSimpleMime('image/jpeg'),
    FileFormatEx.fromSimpleMime('application/pdf'),
    { extension: 'doc', mime: 'application/msword' },
    { extension: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  ],
  TAG_INIT: 'TAG_INIT',
  TAG_SEND_SMS: 'TAG_SEND_SMS',
  TAG_VALIDATE_CODE: 'TAG_VALIDATE_CODE',
  TAG_SEND: 'TAG_SEND',
}

export default DPOViewModel
