import Parse from "parse";
import { redirect } from "./utils/path";
import { setupIonicReact } from "@ionic/react";

function getEnv(name: string, defaultValue?: string): string {
  const key = `REACT_APP_${name}`;
  const env = process.env[key] ?? defaultValue ?? "ENV VAR undefined";
  return env;
}

// RETURNS defaultValue INSIDE getEnv
const ENVIRONMENT: string = getEnv("", "QA");

// RETURNS REACT_APP_QA_PARSE_SERVER_URL OR REACT_APP_QA_PARSE_APPLICATION_ID
function getEnvironmentEnv(name: string): string {
  return getEnv(`${ENVIRONMENT}_${name}`);
}

const Configurations = {
  ENVIRONMENT,
  parse: {
    HOST_URL: redirect(getEnvironmentEnv("PARSE_SERVER_URL"), ""),
    SERVER_URL: getEnvironmentEnv("PARSE_SERVER_URL"),
    APP_ID: getEnvironmentEnv("PARSE_APPLICATION_ID"),
    DEFAULT_HEADERS: {
      "x-parse-application-id": getEnvironmentEnv("PARSE_APPLICATION_ID"),
    },
  },
  email: {
    SUPPORT_ACCOUNT: "support@ysosapp.zohodesk.com",
  },
  APP_COLOR: "#75bccd",
  async initialize() {
    const { parse } = this;
    Parse.initialize(parse.APP_ID);
    Parse.serverURL = parse.SERVER_URL;

    setupIonicReact({
      rippleEffect: false,
      mode: "md",
      innerHTMLTemplatesEnabled: true,
    });
  },
};

export default Configurations;
