import LiveData from '../../liveData/LiveData'
import Trigger from '../../liveData/Trigger'
import Navigator from '../../navigation/Navigator'
import Localization from '../../plugins/localization/Localization'

export enum SMSValidatorDialogState {
  NONE,
  PHONE_CONFIRMATION,
  SUPPORT,
  CODE,
  INVALID_CODE,
  FINISHED
}

export default interface SMSValidator {
  readonly dialogState: LiveData<SMSValidatorDialogState>
  readonly phone: LiveData<string>
  readonly code: LiveData<string>

  readonly validatedTrigger: Trigger

  init(navigator: Navigator, localization: Localization): void
  deinit(): void

  startValidation(phone: string): void
  sendCode(): void
  resendCode(): void
  cancelSupport(): void
  acceptSupport(): void
  validateCode(): void
  retryInputCode(): void
  finishValidation(): void
}
