import AppErrorCode from "../../errors/AppErrorCode";
import { LiveDataEx } from "../../liveData/LiveData";
import LiveDataImpl from "../../liveData/LiveDataImpl";
import TriggerImpl from "../../liveData/TriggerImpl";
import Navigator from "../../navigation/Navigator";
import Localization from "../../plugins/localization/Localization";
import formatPhone from "../../utils/formatting/phone";
import { NULL_FUNCTION } from "../../utils/object";
import SMSValidator, { SMSValidatorDialogState } from "./SMSValidator";

export default class SMSValidatorImpl implements SMSValidator {
  private validatedPhone: string;

  private navigator: Navigator;

  private localization: Localization;

  constructor(
    private readonly sender: (phone: string) => Promise<any>,
    private readonly validater: (
      phone: string,
      code: string
    ) => Promise<boolean>
  ) {}

  readonly dialogState = new LiveDataImpl(SMSValidatorDialogState.NONE);

  readonly codeDialogTitle = new LiveDataImpl("");

  readonly phone = new LiveDataImpl("");

  readonly code = new LiveDataImpl("");

  readonly validatedTrigger = new TriggerImpl();

  init = (navigator: Navigator, localization: Localization) => {
    this.navigator = navigator;
    this.localization = localization;
  };

  deinit = () => {
    LiveDataEx.disposeAllIn(this);
  };

  startValidation = (phone: string) => {
    if (phone === this.validatedPhone) {
      this.validatedTrigger.fire();
    } else {
      this.phone.set(phone);
      this.code.reset();
      this.dialogState.set(SMSValidatorDialogState.PHONE_CONFIRMATION);
    }
  };

  sendCode = async () => {
    try {
      await this.sender(this.phone.value);
    } catch (ex: any) {
      if (ex.code !== AppErrorCode.SMS_LIMIT_REACHED) {
        return;
      }
    }
    this.dialogState.set(SMSValidatorDialogState.CODE);
  };

  resendCode = async () => {
    this.dialogState.reset();
    try {
      await this.sender(this.phone.value);
    } catch (ex: any) {
      if (ex.code === AppErrorCode.SMS_LIMIT_REACHED) {
        this.dialogState.set(SMSValidatorDialogState.SUPPORT);
        return;
      }
    }
    this.dialogState.set(SMSValidatorDialogState.CODE);
  };

  cancelSupport = () => this.dialogState.set(SMSValidatorDialogState.CODE);

  acceptSupport = () => {
    this.cancelSupport();
    this.navigator.support(
      this.localization.strings.subject_support_sms,
      this.localization.strings.template_body_support_sms(
        formatPhone(this.phone.value, true)
      )
    );
  };

  validateCode = () =>
    this.validater(this.phone.value, this.code.value)
      .then((validated) =>
        this.dialogState.set(
          validated
            ? SMSValidatorDialogState.FINISHED
            : SMSValidatorDialogState.INVALID_CODE
        )
      )
      .catch(NULL_FUNCTION);

  retryInputCode = () => this.dialogState.set(SMSValidatorDialogState.CODE);

  finishValidation = () => {
    this.validatedPhone = this.phone.value;
    this.dialogState.reset();
    this.validatedTrigger.fire();
  };
}
