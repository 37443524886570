import { isFunction } from 'lodash'

export default function when<TVal, TResult>(
  value: TVal,
  ifThen: [
    TVal | ((value: TVal) => boolean), TResult | ((value: TVal) => TResult)
  ][],
): TResult {
  const $case = ifThen.find(
    ([cmpValue]) => (isFunction(cmpValue) ? cmpValue(value) : value === cmpValue),
  )

  if ($case) {
    return isFunction($case[1]) ? ($case[1] as any)(value) : $case[1]
  }

  throw new Error(`No suitable case for ${value}`)
}
