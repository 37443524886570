// eslint-disable-next-line import/no-extraneous-dependencies
import type { History, Location, LocationDescriptor } from "history";
import { injectable } from "inversify";
import Configurations from "../Configurations";
import LoggingRepository from "../repositories/logging/LoggingRepository";
import { NULL_FUNCTION } from "../utils/object";
import { combine } from "../utils/path";
import Navigator, { NavigatorOptions } from "./Navigator";

const URL_APP = "https://ysosapp.com.br";

type LocationState = {
  previousLocation?: Location;
};

@injectable()
export default class HistoryNavigator implements Navigator {
  constructor(
    private readonly loggingRepository: LoggingRepository,
    private readonly history: History<any>,
    private readonly webLastLocation?: Location<any>
  ) {}

  private push = (
    route: string,
    query?: any,
    { replaces, fromPath, useLocation }: NavigatorOptions = {}
  ) => {
    this.loggingRepository.navigation(route).catch(NULL_FUNCTION);

    const queryString = Object.entries(query ?? {})
      .map(([key, value]) => `${key}=${value ?? ""}`)
      .join("&");
    const state: LocationState = { previousLocation: this.history.location };
    const locationDescriptor: LocationDescriptor = {
      pathname: fromPath ? combine(fromPath, route) : route,
      search: `?${queryString}`,
      state,
    };

    if (useLocation) {
      window.location.href =
        locationDescriptor.pathname! + locationDescriptor.search;
      return;
    }

    if (replaces) {
      this.history.replace(locationDescriptor);
      return;
    }

    this.history.push(locationDescriptor);
  };

  back = () => {
    if (this.webLastLocation) {
      this.loggingRepository
        .navigation(this.webLastLocation.pathname)
        .catch(NULL_FUNCTION);
    }
    this.history.goBack();
  };

  close = () => {
    window.location.href = URL_APP;
  };

  support = (subject: string, body: string) => {
    const href = `mailto:${
      Configurations.email.SUPPORT_ACCOUNT
    }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = href;
  };

  getQuery = <T>(): T => {
    const params = new URLSearchParams(this.history.location.search);
    const query = Array.from(params.entries()).reduce((agg, [key, value]) => {
      agg[key] = value; // eslint-disable-line no-param-reassign
      return agg;
    }, {} as { [key: string]: any });
    return query as T;
  };

  isIn = (route: string) =>
    new RegExp(`${route}(/|$)`).test(window.location.pathname);
}
