import { injectable } from 'inversify'
import NotificationCenter, { NotificationObserver } from './NotificationCenter'
import NotificationType, { NotificationTypeEx } from './NotificationType'
import NotificationObserverDisposable from './NotificationObserverDisposable'

@injectable()
export default class NotificationCenterImpl implements NotificationCenter {
  private readonly observers = new Map(
    NotificationTypeEx.allValues.map((n) => [n, new Set<NotificationObserver>()]),
  )

  post = (type: NotificationType, withUserInfo: any) => {
    Array.from(this.observers.get(type)!)
      .filter((o) => o(type, withUserInfo))
      .forEach((o) => this.removeObserver(type, o))
  }

  observe = (
    type: NotificationType, observer: NotificationObserver,
  ): NotificationObserverDisposable => {
    const observers = this.observers.get(type)!
    observers.add(observer)
    return new NotificationObserverDisposable(this, type, observer)
  }

  removeObserver = (type: NotificationType, observer: NotificationObserver) => {
    const observers = this.observers.get(type)!
    return observers.delete(observer)
  }
}
