import React from "react";
import { createRoot } from "react-dom/client";
import "reflect-metadata";
import App from "./App";
import Configurations from "./Configurations";
import ParseApiInitializer from "./services/utils/parse/ParseApiInitializer";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");

const root = createRoot(container!);

Configurations.initialize();

ParseApiInitializer.init();

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
