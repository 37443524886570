import { identity } from 'lodash'
import LiveData from '../liveData/LiveData'
import LiveDataImpl from '../liveData/LiveDataImpl'

export type FormData<T> = {
  data: T
  error?: string
}

export const FormDataEx = {
  default: <T>(defaultValue: T): FormData<T> => ({ data: defaultValue }),
}

export const LiveFormDataEx = {
  new: <T>(
    defaultValue: T,
    validation: (data: T) => string,
    modifier: (data: T) => T = identity,
  ) => new LiveDataImpl(
    FormDataEx.default(defaultValue),
    (formData) => {
      const fixdData = modifier(formData.data)
      return {
        ...formData,
        data: fixdData,
        error: validation(fixdData),
      }
    },
  ),

  setData: <T>(liveData: LiveData<FormData<T>>, data: T) => {
    liveData.set(({
      ...liveData.value,
      data,
    }))
  },
}
