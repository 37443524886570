import { useHistory } from 'react-router'
import { useLastLocation } from 'react-router-last-location'
import useIoC from '../dependencyInjection/useIoC'
import LoggingRepository from '../repositories/logging/LoggingRepository'
import HistoryNavigator from './HistoryNavigator'
import Navigator from './Navigator'

export default function useNavigator(): Navigator {
  const history = useHistory()
  const lastLocation = useLastLocation()
  const navigator = new HistoryNavigator(
    useIoC().get(LoggingRepository.SYMBOL),
    history,
    lastLocation ?? undefined,
  )
  return navigator
}
