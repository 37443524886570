import { Subject } from 'rxjs'
import LiveData, { LiveDataSetOptions } from './LiveData'
import LiveDataDisposable from './LiveDataDisposable'

export default class LiveDataImpl<T> implements LiveData<T> {
  private subject = new Subject<T>()

  protected mValue: T

  constructor(
    private defaultValue: T,
    private prepareNewValue: (newValue: T) => T = (newValue) => newValue,
  ) {
    this.mValue = defaultValue
  }

  get value(): T {
    return this.mValue
  }

  set = (value: T, options: LiveDataSetOptions = {}) => {
    const preparedValue = this.prepareNewValue(value)
    this.internalSet(preparedValue, options)
  }

  private internalSet = (value: T, options: LiveDataSetOptions = {}) => {
    if (value !== this.mValue || options.force) {
      this.mValue = value
      if (!options.silent) {
        if (!this.subject.isStopped) {
          this.subject.next(value)
        }
      }
    }
  }

  reset = () => this.internalSet(this.defaultValue)

  observe = (observer: (newValue: T) => void) => {
    if (this.subject.isStopped) {
      this.subject = new Subject()
    }
    const disposable = this.subject.subscribe(observer)
    return new LiveDataDisposable(disposable)
  }

  dispose = () => {
    // FIXME lbaglie: find a way to unsubscribe that allows reuse of the LiveData
    // later. Pay attention to useLiveData()
    // this.subject.unsubscribe()
    this.reset()
  }
}
