import InjectableType from '../dependencyInjection/InjectableType'
import AppError from './AppError'

interface ErrorParser {
  parse(error: any): AppError
}

const ErrorParser: InjectableType = {
  SYMBOL: Symbol.for('ErrorParser'),
}

export default ErrorParser
