type FileFormat = {
  extension: string
  mime: string
}

export default FileFormat

export const FileFormatEx = {
  fromSimpleMime: (mime: string): FileFormat => ({
    extension: mime.split('/')[1],
    mime,
  }),
  fileExtension: ({ extension }: FileFormat) => `.${extension}`,
  is: ({ extension, mime }: FileFormat, formatStr: string) => (
    extension === formatStr || mime === formatStr
  ),
}
