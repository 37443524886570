import { inject, injectable } from 'inversify'
import LoggingService from '../../services/logging/LoggingService'
import LoggingRepository from './LoggingRepository'

@injectable()
export default class LoggingRepositoryImpl implements LoggingRepository {
  @inject(LoggingService.SYMBOL)
  private readonly service: LoggingService

  navigation = (route: string) => this.service.event('USER_NAVIGATED', { route })
}
