import InjectableType from '../../dependencyInjection/InjectableType'
import StringsTypes from './StringsTypes'

interface Localization {
  format(str: string, ...args: any[]): string
  formatLineBreaks(str: string): string
  joinLines(comps: string[]): string
  readonly strings: StringsTypes
}

const Localization: InjectableType = {
  SYMBOL: Symbol.for('Localization'),
}

export default Localization
