import { last } from 'lodash'

export function ensureNoPrecedingSlash(component: string): string {
  const slashRegex = /^\//g
  return component.replace(slashRegex, '')
}

export function ensureNoSuccedingSlash(component: string): string {
  const slashRegex = /\/$/g
  return component.replace(slashRegex, '')
}

export function combine(...components: string[]): string {
  return `${
    ensureNoSuccedingSlash(components[0])
  }/${
    components.slice(1).map((c) => ensureNoPrecedingSlash(c)).join('/')
  }`
}

export function fileName(name: string, withExtension: string): string {
  return `${name}.${withExtension}`
}

export function lastPathComponent(path: string): string {
  return last(path.split('/'))!
}

export function extension(ofFilename: string) {
  return last(ofFilename.split('.'))!
}

export function redirect(url: string, to: string | undefined): string {
  const urlObj = new URL(url)
  urlObj.pathname = to ?? ''
  return urlObj.toString()
}
