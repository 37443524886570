import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React, { useCallback, useEffect, useState } from "react";
import ContinueBottomButton from "../../components/bottomButton/ContinueBottomButton";
import {
  FileField,
  InputTextField,
  SelectField,
  SelectOption,
  TextAreaField,
} from "../../components/field/Field";
import InternationalPhoneInput, {
  ALLOWED_COUNTRIES,
} from "../../components/international-phone-input/InternationalPhoneInput";
import AsyncIndicatorsComponent from "../../components/liveData/AsyncIndicatorsComponent";
import SMSValidatorView from "../../components/sms/SMSValidatorView";
import { bindReset } from "../../liveData/bindings";
import {
  bindFormDataToFileInput,
  bindFormDataToIonEventDetail,
  bindFormDataToValue,
} from "../../liveData/formDataBindings";
import useLiveData from "../../liveData/useLiveData";
import useLocalization from "../../plugins/localization/useLocalization";
import DPOViewModel, {
  DPODialogState,
} from "../../viewModels/dpo/DPOViewModel";
import useViewModel from "../../viewModels/useViewModel";
import "./DPOPage.scss";

const DPOPage: React.FC = () => {
  console.log("DPO Page");

  const { strings } = useLocalization();

  const viewModel: DPOViewModel = useViewModel(DPOViewModel);
  const [dialogState] = useLiveData(viewModel.dialogState);
  const [name] = useLiveData(viewModel.name);
  const [email] = useLiveData(viewModel.email);
  const [phone] = useLiveData(viewModel.phone);
  const [message] = useLiveData(viewModel.message);
  const [attachment] = useLiveData(viewModel.attachment);
  const [sendButtonEnabled] = useLiveData(viewModel.sendButtonEnabled);

  const {
    contactorTypeController,
    membershipController,
    reasonController,
    smsValidator,
  } = viewModel;
  const [contactorTypes] = useLiveData(contactorTypeController.items);
  const [selectedContactorType] = useLiveData(
    contactorTypeController.selectedItem
  );
  const [yesno] = useLiveData(membershipController.items);
  const [isMember] = useLiveData(membershipController.selectedItem);
  const [reasons] = useLiveData(reasonController.items);
  const [selectedReason] = useLiveData(reasonController.selectedItem);

  const [country, setCountry] = useState(ALLOWED_COUNTRIES[0]);

  const onChangeCountry = (code: string) => {
    setCountry(code);
  };

  const renderDefaultCounty = useCallback(() => {
    if (strings?.getLanguage() === "pt") setCountry(ALLOWED_COUNTRIES[0]);
    else setCountry(ALLOWED_COUNTRIES[1]);
  }, [strings]);

  useEffect(() => {
    viewModel.init(strings.getLanguage());
    renderDefaultCounty();
    return viewModel.deinit;
  }, [strings, viewModel, renderDefaultCounty]);

  return (
    <IonPage id="dpo">
      <IonHeader className="header-borderless">
        <IonToolbar color="background">
          <IonButtons slot="start">
            <IonButton onClick={viewModel.close} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{strings.title_dpo}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="background">
        <div className="dpo__header">{strings.message_dpo_reasons}</div>
        <div className="dpo__form">
          <SelectField
            className="dpo__form__field"
            title={strings.label_dpo_form_contactor}
            options={contactorTypes.map(
              ({ item }): SelectOption => ({
                text: strings.options_contactor_type(item),
                value: item,
              })
            )}
            value={selectedContactorType}
            onSelect={contactorTypeController.select}
          />
          <InputTextField
            className="dpo__form__field"
            title={strings.label_dpo_form_name}
            error={name.error}
            autoCorrect
            inputMode="text"
            value={name.data}
            onChange={bindFormDataToIonEventDetail(viewModel.name)}
          />
          <InputTextField
            className="dpo__form__field"
            error={email.error}
            title={strings.label_dpo_form_email}
            inputMode="email"
            value={email.data}
            onChange={bindFormDataToIonEventDetail(viewModel.email)}
          />
          <SelectField
            className="dpo__form__field"
            title={strings.label_dpo_form_active}
            options={yesno.map(
              ({ item }): SelectOption => ({
                text: strings.options_boolean_yesno(item),
                value: item,
              })
            )}
            value={{ value: isMember }}
            onSelect={membershipController.select}
          />
          <InternationalPhoneInput
            country={country}
            label={strings.label_dpo_form_phone}
            className="dpo__form__field"
            error={phone.error}
            locales={strings}
            onChange={bindFormDataToIonEventDetail(viewModel.phone)}
            onChangeCountry={onChangeCountry}
            value={phone.data}
          />
          <SelectField
            className="dpo__form__field"
            title={strings.label_dpo_form_reason}
            options={reasons.map(
              ({ item }): SelectOption => ({
                text: item.description,
                value: item.id,
              })
            )}
            value={selectedReason?.id}
            onSelect={reasonController.select}
          />
          <TextAreaField
            className="dpo__form__field"
            title={strings.label_dpo_form_message}
            error={message.error}
            autoCorrect
            value={message.data}
            onChange={bindFormDataToIonEventDetail(viewModel.message)}
          />
          <FileField
            className="dpo__form__field"
            title={strings.label_dpo_form_attachment}
            error={attachment.error}
            value={attachment.data}
            onSelect={bindFormDataToFileInput(viewModel.attachment)}
            onRemoveFile={bindFormDataToValue(viewModel.attachment, undefined)}
            acceptedTypes={DPOViewModel.ATTACHMENT_ALLOWED_FORMATS}
            maxSize={DPOViewModel.ATTACHMENT_MAX_SIZE}
          />
        </div>
        <ContinueBottomButton
          onClick={viewModel.send}
          visible={sendButtonEnabled}
        />
      </IonContent>
      <IonAlert
        isOpen={dialogState === DPODialogState.SENT}
        onDidDismiss={bindReset(viewModel.dialogState, DPODialogState.SENT)}
        header={strings.dialog_title_dpo_sent}
        message={strings.dialog_message_dpo_sent}
        buttons={[
          {
            text: strings.ok,
            handler: viewModel.finish,
          },
        ]}
      />
      <SMSValidatorView validator={smsValidator} />
      <AsyncIndicatorsComponent
        viewModel={viewModel}
        tags={[
          DPOViewModel.TAG_INIT,
          DPOViewModel.TAG_SEND_SMS,
          DPOViewModel.TAG_VALIDATE_CODE,
          DPOViewModel.TAG_SEND,
        ]}
      />
    </IonPage>
  );
};

export default DPOPage;
