import { Container, injectable } from 'inversify'
import DIPlugin, { Constructable } from './DIPlugin'

@injectable()
export default class DIPluginImpl implements DIPlugin {
  constructor(
    private readonly container: Container,
    private readonly mappings: Map<symbol, Constructable>,
  ) {}

  rebind = (symbol: symbol) => {
    this.container.unbind(symbol)
    this.container.bind(symbol).to(this.mappings.get(symbol)!)
  }
}
