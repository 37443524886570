import { RangeChangeEventDetail } from '@ionic/core'
import { isFunction } from 'lodash'
import Range, { RangeEx } from '../plainTypes/Range'
import { contains } from '../utils/array'
import { DO_NOTHING } from '../utils/function'
import LiveData from './LiveData'

export type BindingFunction<ARG = any, RESULT = any> = (arg: ARG) => RESULT

export function bindToEventArg<T>(liveData: LiveData<T>): BindingFunction<T> {
  return (args: T) => liveData.set(args)
}

export function bindToValue<T>(fn: (arg: T) => any, value: T): BindingFunction
export function bindToValue<T>(liveData: LiveData<T>, value: T): BindingFunction
export function bindToValue<T>(dataOrFn: any, value: T): BindingFunction {
  return () => {
    if (isFunction(dataOrFn)) {
      dataOrFn(value)
    } else {
      dataOrFn.set(value, { force: true })
    }
  }
}

export function bindToValues<T1, T2>(
  fn: (arg1: T1, arg2: T2) => void, value1: T1, value2: T2,
): BindingFunction
export function bindToValues(
  fn: (...args: any) => void, ...args: any
): BindingFunction {
  return () => fn(...args)
}

export function bindReset<T>(liveData: LiveData<T>, onlyIf?: T): BindingFunction {
  return () => {
    if (!onlyIf || liveData.value === onlyIf) {
      liveData.reset()
    }
  }
}

export function bindEnumToIonEventDetail<T extends string>(
  liveData: LiveData<T>,
): BindingFunction<CustomEvent<{ value: string | null | undefined }>> {
  return ({ detail: { value } }) => liveData.set(value as T)
}

export function bindToIonEventDetail<T>(
  liveData: LiveData<T>,
): BindingFunction<CustomEvent<{ value: T | null | undefined }>>
export function bindToIonEventDetail<T>(
  fn: (arg: T) => void,
): BindingFunction<CustomEvent<{ value: T | null | undefined }>>
export function bindToIonEventDetail<T>(
  dataOrFn: LiveData<T> | ((arg: T) => void),
): BindingFunction<CustomEvent<{ value: T | null | undefined }>> {
  return ({ detail: { value } }) => {
    if (isFunction(dataOrFn)) {
      dataOrFn(value as T)
    } else {
      dataOrFn.set(value as T)
    }
  }
}

export function bindToIonRangeEventValue(
  liveData: LiveData<number>,
): (event: CustomEvent<RangeChangeEventDetail>) => void
export function bindToIonRangeEventValue(
  callback: (value: number) => any,
): (event: CustomEvent<RangeChangeEventDetail>) => void
export function bindToIonRangeEventValue(
  dataOrCallback: LiveData<number> | ((value: number) => any),
): (event: CustomEvent<RangeChangeEventDetail>) => void {
  return ({ detail: { value } }) => {
    const aNumber = value as number
    return isFunction(dataOrCallback)
      ? dataOrCallback(aNumber)
      : dataOrCallback.set(aNumber)
  }
}

export function bindToIonRangeEventRange(
  liveData: LiveData<Range>,
): (event: CustomEvent<RangeChangeEventDetail>) => void
export function bindToIonRangeEventRange(
  fn: (value: Range) => any
): (event: CustomEvent<RangeChangeEventDetail>) => void
export function bindToIonRangeEventRange(
  dataOrFn: LiveData<Range> | ((value: Range) => any),
): (event: CustomEvent<RangeChangeEventDetail>) => void {
  return ({ detail: { value } }) => {
    const range = value as Range
    return isFunction(dataOrFn)
      ? dataOrFn(range)
      : dataOrFn.set(range)
  }
}

export function bindToMaterialSliderValue(
  liveData: LiveData<number>,
): (event: React.ChangeEvent<{}>, value: number | number[]) => void
export function bindToMaterialSliderValue(
  callback: (value: number) => any,
): (event: React.ChangeEvent<{}>, value: number | number[]) => void
export function bindToMaterialSliderValue(
  dataOrCallback: LiveData<number> | ((value: number) => any),
): (event: React.ChangeEvent<{}>, value: number | number[]) => void {
  return (_e, value) => (
    isFunction(dataOrCallback)
      ? dataOrCallback(value as number)
      : dataOrCallback.set(value as number)
  )
}

export function bindToMaterialSliderRange(
  liveData: LiveData<Range>,
): (event: React.ChangeEvent<{}>, value: number | number[]) => void
export function bindToMaterialSliderRange(
  callback: (value: Range) => any
): (event: React.ChangeEvent<{}>, value: number | number[]) => void
export function bindToMaterialSliderRange(
  dataOrCallback: LiveData<Range> | ((value: Range) => any),
): (event: React.ChangeEvent<{}>, value: number | number[]) => void {
  return (_e, value) => {
    const fixdValue = value as number[]
    const range = RangeEx.fromArray(fixdValue)
    return isFunction(dataOrCallback) ? dataOrCallback(range) : dataOrCallback.set(range)
  }
}

export function bindSetElementToggling<T>(
  liveData: LiveData<Set<T>>,
): BindingFunction<T | undefined> {
  return (arg) => {
    if (!arg) {
      return
    }
    const entries = new Set(liveData.value)
    if (entries.has(arg)) {
      entries.delete(arg)
    } else {
      entries.add(arg)
    }
    liveData.set(entries)
  }
}

export function bindBooleanToggling(liveData: LiveData<boolean>): BindingFunction {
  return () => liveData.set(!liveData.value)
}

export function bindCompletableEvent<ARG, RESULT>(
  method: (args?: ARG) => Promise<RESULT>, arg?: ARG,
): BindingFunction<CustomEvent<any>> {
  return async (e) => {
    await method(arg).catch(DO_NOTHING)
    const completer = e.target ?? e.detail
    completer.complete()
  }
}

export function bindOnKeyboardEvent<T>(
  method: () => T, keyCodes: number[] = [13],
): BindingFunction<React.KeyboardEvent<HTMLIonInputElement>> {
  return (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (contains(keyCodes, e.which)) {
      method()
    }
  }
}

export function persistentEventBind<T>(binding: BindingFunction<T>): BindingFunction<T> {
  return (e: any) => {
    e.persist()
    return binding(e)
  }
}

export function jointBind<T>(...bindings: BindingFunction<any>[]): BindingFunction<T> {
  return (e: any) => bindings.forEach((binding) => binding(e))
}

export function conditionalBind<T>(
  conditional: (args: T) => boolean,
  binding: BindingFunction<T>,
  elseBinding?: BindingFunction<T>,
): BindingFunction<T | undefined> {
  return (e: any) => {
    if (conditional(e)) {
      return binding(e)
    }
    if (elseBinding) {
      return elseBinding(e)
    }
    return undefined
  }
}
