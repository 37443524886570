import { inject, injectable } from 'inversify'
import { isFunction } from 'lodash'
import AppError from '../errors/AppError'
import ErrorParser from '../errors/ErrorParser'
import { LiveDataEx } from '../liveData/LiveData'
import LiveDataImpl from '../liveData/LiveDataImpl'
import UndefinableLiveDataImpl from '../liveData/UndefinableLiveDataImpl'
import ActionData from '../plainTypes/ActionData'
import AsyncViewModel from './AsyncViewModel'
import LocalizedViewModelImpl from './LocalizedViewModelImpl'

@injectable()
export default abstract class AsyncViewModelImpl extends LocalizedViewModelImpl
  implements AsyncViewModel {
  @inject(ErrorParser.SYMBOL)
  protected readonly errorParser: ErrorParser

  readonly error = new UndefinableLiveDataImpl<ActionData<Error>>()

  readonly loading = new LiveDataImpl<ActionData<boolean>>({ value: false, tag: '' })

  protected performAsyncSafe = <T>(
    action: Promise<T> | (() => T | Promise<T>),
    tag: string,
    ignoresError?: boolean | ((e: AppError) => boolean),
  ): Promise<AppError | T> => this.performAsync(action, tag, ignoresError)
    .catch((ex) => ex)

  protected performAsync = async <T>(
    action: Promise<T> | (() => T | Promise<T>),
    tag: string,
    ignoresError?: boolean | ((e: AppError) => boolean),
  ): Promise<T> => {
    this.loading.set({ tag, value: true })
    try {
      if (isFunction(action)) {
        return await action()
      }
      return await action
    } catch (ex) {
      const parsed = this.errorParser.parse(ex)
      const isIgnoreFunction = isFunction(ignoresError) && !ignoresError(parsed)
      const isIgnoreBoolean = !isFunction(ignoresError) && !ignoresError
      if (isIgnoreFunction || isIgnoreBoolean) {
        this.error.set({ tag, value: parsed })
      }
      throw parsed
    } finally {
      // HACK lbaglie: necessary, as IonLoading is not hidden when updated
      // several times in a short timespan.
      // TODO lbaglie: find a decent workaround for this problem.
      setTimeout(() => this.loading.set({ tag, value: false }), 50)
    }
  }

  deinit = () => this.deinitFun()

  protected deinitFun() {
    LiveDataEx.disposeAllIn(this)
  }
}
