import { InputChangeEventDetail } from "@ionic/core";
import {
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { isString } from "lodash";
import React, { useRef, useState } from "react";
import InputMask from "react-input-mask";
import FileFormat from "../../plainTypes/FileFormat";
import useLocalization from "../../plugins/localization/useLocalization";
import { convertFileSize } from "../../utils/number";
import { concat } from "../../utils/string";
import ClickableDiv from "../clickable/ClickableDiv";
import "./Field.scss";

export type FieldProps = {
  className?: string;
  title: string;
  error?: string;
  instruction?: string;
};

export type TextFieldProps = FieldProps & {
  autoCorrect?: boolean;
  value: string;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  maxLength?: number;
  onChange: any;
  // onChange: (e: CustomEvent<InputChangeEventDetail>) => void | any;
};

export type InputTextFieldProps = TextFieldProps & {
  mask?: string | (string | RegExp)[];
};

export const Field: React.FC<React.PropsWithChildren<FieldProps>> = ({
  children,
  title,
  className,
  error,
  instruction,
}: React.PropsWithChildren<FieldProps>) => (
  <div className={`field ${className}`}>
    <div className="field__title">{title}</div>
    <div className="field__body">{children}</div>
    <div className="field_instruction">{instruction}</div>
    <div className="field__error">
      <IonText color="danger">{error}</IonText>
    </div>
  </div>
);

export const InputTextField: React.FC<InputTextFieldProps> = ({
  title,
  className,
  error,
  autoCorrect,
  inputMode,
  value,
  onChange,
  maxLength,
  mask,
}: InputTextFieldProps) => {
  const [canShowError, setCanShowError] = useState(false);
  return (
    <Field
      title={title}
      className={className}
      error={canShowError ? error : undefined}
    >
      {mask ? (
        <InputMask
          type="text"
          className="field__body__input"
          inputMode={inputMode}
          value={value}
          mask={mask}
          alwaysShowMask
          placeholder="_"
          onBlur={() => setCanShowError(true)}
          onChange={(e) =>
            onChange({ detail: { value: e.currentTarget.value } } as any)
          }
        />
      ) : (
        <input
          type="text"
          className="field__body__input"
          inputMode={inputMode}
          autoCorrect={autoCorrect ? "on" : "off"}
          value={value}
          maxLength={maxLength}
          onBlur={() => setCanShowError(true)}
          onChange={(e) =>
            onChange({ detail: { value: e.currentTarget.value } } as any)
          }
        />
      )}
    </Field>
  );
};

export const TextAreaField: React.FC<TextFieldProps> = ({
  title,
  className,
  error,
  autoCorrect,
  inputMode,
  value,
  onChange,
  maxLength,
}: TextFieldProps) => {
  const [canShowError, setCanShowError] = useState(false);
  return (
    <Field
      title={title}
      className={className}
      error={canShowError ? error : undefined}
    >
      <IonTextarea
        className="field__body__textarea"
        autoCorrect={autoCorrect ? "on" : "off"}
        autoGrow
        inputMode={inputMode}
        value={value}
        maxlength={maxLength}
        onIonChange={onChange}
        onIonBlur={() => setCanShowError(true)}
      />
    </Field>
  );
};

export type SelectOption =
  | string
  | {
      value: any;
      text?: string;
    };

export const SelectOptionEx = {
  getValue: (of: SelectOption) => (isString(of) ? of : of.value),
  getText: (of: SelectOption) => (isString(of) ? of : of.text) ?? of.toString(),
};

export type SelectFieldProps = FieldProps & {
  options: SelectOption[];
  value?: SelectOption;
  onSelect: (idx: number) => void;
};

export const SelectField: React.FC<SelectFieldProps> = ({
  title,
  className,
  error,
  options,
  value,
  onSelect,
}: SelectFieldProps) => {
  const { strings } = useLocalization();

  return (
    <Field title={title} className={className} error={error}>
      <IonSelect
        className="field__body__select dpo__form__reason"
        interfaceOptions={{ header: title }}
        value={value ? SelectOptionEx.getValue(value) : undefined}
        okText={strings.ok}
        cancelText={strings.cancel}
        onIonChange={(e) => {
          const idx = options.findIndex(
            (o) => SelectOptionEx.getValue(o) === e.detail.value
          );
          if (idx >= 0) {
            onSelect(idx);
          }
        }}
      >
        {options.map((o) => (
          <IonSelectOption
            value={SelectOptionEx.getValue(o)}
            key={SelectOptionEx.getValue(o).toString()}
          >
            {SelectOptionEx.getText(o)}
          </IonSelectOption>
        ))}
      </IonSelect>
    </Field>
  );
};

export type FileFieldProps = FieldProps & {
  value: File | undefined;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: (e: any) => void;
  acceptedTypes?: FileFormat[];
  maxSize?: number;
};

export const FileField: React.FC<FileFieldProps> = ({
  title,
  className,
  error,
  value,
  onSelect,
  onRemoveFile,
  acceptedTypes,
  maxSize,
}: FileFieldProps) => {
  const { strings } = useLocalization();

  const fileRef = useRef<HTMLInputElement>(null);
  const handleFocus = () => {
    console.log("DUDE");
    fileRef.current!.click();
  };

  return (
    <Field
      title={title}
      className={className}
      error={error}
      instruction={concat(
        maxSize
          ? strings.template_field_file_maxsize(
              convertFileSize(maxSize, "bytes", "mb")
            )
          : undefined,
        acceptedTypes
          ? strings.template_field_file_formats(acceptedTypes)
          : undefined
      )}
    >
      <div className="field__body__file">
        <input
          key={value?.name}
          type="file"
          ref={fileRef}
          className="field__body__file__file-input"
          onChange={onSelect}
        />
        <ClickableDiv
          className="field__body__file__input"
          onClick={handleFocus}
        >
          <IonText color={value ? "textOnBackground" : "lightTextOnBackground"}>
            {value?.name ?? strings.placeholder_field_file}
          </IonText>
        </ClickableDiv>
        <IonButton
          className="field__body__file__clean"
          color="transparent-contrast"
          onClick={onRemoveFile}
        >
          <IonIcon icon={close} />
        </IonButton>
      </div>
    </Field>
  );
};
