import ObservableData from './ObservableData'

export interface LiveDataSetOptions {
  force?: boolean
  silent?: boolean
}

export default interface LiveData<T> extends ObservableData<T> {
  readonly value: T
  set(value: T, options?: LiveDataSetOptions): void
  reset(): void
}

export const LiveDataEx = {
  disposeAllIn: (obj: any) => Object.values(obj).forEach((v: any) => {
    if (v && v.reset && v.dispose) {
      v.dispose()
    }
  }),
}
