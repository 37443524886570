import InjectableType from '../../dependencyInjection/InjectableType'

export type Constructable = { new(...args: any[]): any }

interface DIPlugin {
  rebind(symbol: Symbol): void
}

const DIPlugin: InjectableType = {
  SYMBOL: Symbol.for('DIPlugin'),
}

export default DIPlugin
