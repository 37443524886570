import React from 'react'
import LiveData from '../../liveData/LiveData'
import ActionData from '../../plainTypes/ActionData'
import LiveDataErrorToast from './LiveDataErrorToast'
import LiveDataLoading from './LiveDataLoading'

export interface AsyncIndicatorsComponentProps {
  viewModel: {
    loading: LiveData<ActionData<boolean>>,
    error: LiveData<ActionData<Error> | undefined>
  }
  tags?: string[]
  loadingTags?: string[]
  errorTags?: string[]
}

const AsyncIndicatorsComponent: React.FC<AsyncIndicatorsComponentProps> = ({
  viewModel, tags, loadingTags, errorTags,
}: AsyncIndicatorsComponentProps) => {
  const fixdTags = (tags ?? [])
  const someErrorTags = (errorTags ?? [])
    .concat(fixdTags)
  const someLoadingTags = (loadingTags ?? [])
    .concat(fixdTags)
  return (
    <>
      <LiveDataLoading liveData={viewModel.loading} tags={someLoadingTags} />
      <LiveDataErrorToast liveData={viewModel.error} tags={someErrorTags} />
    </>
  )
}

export default AsyncIndicatorsComponent
