import { injectable, inject } from 'inversify'
import PhoneRepository from './PhoneRepository'
import PhoneService from '../../services/phone/PhoneService'
import SMSType from '../../plainTypes/phone/SMSType'

@injectable()
export default class PhoneRepositoryImpl implements PhoneRepository {
  @inject(PhoneService.SYMBOL)
  private readonly service: PhoneService

  validatePhone = (phone: string, smsType: SMSType) => this.service.validatePhone(phone, smsType)

  sendSMS = (phone: string, smsType: SMSType) => this.service.sendSMS(phone, smsType)

  checkSMSCode = (
    phone: string, code: string, smsType: SMSType,
  ) => this.service.checkSMSCode(phone, code, smsType)
}
