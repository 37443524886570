import React from 'react'
import { IonLoading } from '@ionic/react'
import ActionData from '../../plainTypes/ActionData'
import LiveData from '../../liveData/LiveData'
import useLiveData from '../../liveData/useLiveData'
import { contains } from '../../utils/array'

export interface LiveDataLoadingProps {
  liveData: LiveData<ActionData<boolean> | undefined>
  tags?: (string | undefined)[]
}

const LiveDataLoading: React.FC<LiveDataLoadingProps> = ({
  liveData, tags,
}: LiveDataLoadingProps) => {
  const [loading] = useLiveData(liveData)
  return (
    <IonLoading
      isOpen={
        !!loading && loading.value && (!tags || contains(tags, loading.tag))
      }
    />
  )
}

export default LiveDataLoading
