import { isNumber } from 'lodash'

enum NotificationType {
  TAB_CHANGE,

  SELF_UPDATED,
  USER_REPORTED,
  USER_LIKED,
  USER_BLOCKED,
  GALLERY_PERMISSION_REQUEST,

  LOG_IN
}

export default NotificationType

const allValues = Object.values(NotificationType).filter((v) => isNumber(v))
  .map((v) => v as NotificationType)

export const NotificationTypeEx = {
  allValues,
}
