import { Container } from 'inversify'
import NotificationCenter from '../controllers/notificationCenter/NotificationCenter'
import NotificationCenterImpl from '../controllers/notificationCenter/NotificationCenterImpl'
import ErrorParser from '../errors/ErrorParser'
import ErrorParserImpl from '../errors/ErrorParserImpl'
import Navigator from '../navigation/Navigator'
import DIPlugin, { Constructable } from '../plugins/dependencyInjection/DIPlugin'
import DIPluginImpl from '../plugins/dependencyInjection/DIPluginImpl'
import Localization from '../plugins/localization/Localization'
import ReactLocalization from '../plugins/localization/ReactLocalization'
import CapacitorLocalStoragePlugin from '../plugins/localStorage/CapacitorLocalStoragePlugin'
import LocalStoragePlugin from '../plugins/localStorage/LocalStoragePlugin'
import DPORepository from '../repositories/dpo/DPORepository'
import DPORepositoryImpl from '../repositories/dpo/DPORepositoryImpl'
import LoggingRepository from '../repositories/logging/LoggingRepository'
import LoggingRepositoryImpl from '../repositories/logging/LoggingRepositoryImpl'
import PhoneRepository from '../repositories/phone/PhoneRepository'
import PhoneRepositoryImpl from '../repositories/phone/PhoneRepositoryImpl'
import DPOParseService from '../services/dpo/DPOParseService'
import DPOService from '../services/dpo/DPOService'
import LoggingParseService from '../services/logging/LoggingParseService'
import LoggingService from '../services/logging/LoggingService'
import PhoneParseService from '../services/phone/PhoneParseService'
import PhoneService from '../services/phone/PhoneService'
import DPOViewModel from '../viewModels/dpo/DPOViewModel'
import DPOViewModelImpl from '../viewModels/dpo/DPOViewModelImpl'

const constructableMappings = new Map<symbol, Constructable>([
  // View Models
  [DPOViewModel.SYMBOL, DPOViewModelImpl],

  // Repositories
  [DPORepository.SYMBOL, DPORepositoryImpl],
  [PhoneRepository.SYMBOL, PhoneRepositoryImpl],
  [LoggingRepository.SYMBOL, LoggingRepositoryImpl],

  // Services
  [DPOService.SYMBOL, DPOParseService],
  [PhoneService.SYMBOL, PhoneParseService],
  [LoggingService.SYMBOL, LoggingParseService],

  // MISC
  [ErrorParser.SYMBOL, ErrorParserImpl],
  [NotificationCenter.SYMBOL, NotificationCenterImpl],

  // Plugins
  [Localization.SYMBOL, ReactLocalization],
  [LocalStoragePlugin.SYMBOL, CapacitorLocalStoragePlugin],
])

const ReactIoCContainer = new Container({ defaultScope: 'Singleton' })
constructableMappings.forEach((clazz, dependency) => {
  ReactIoCContainer.bind(dependency).to(clazz)
})

ReactIoCContainer
  .bind(DIPlugin.SYMBOL)
  .toConstantValue(new DIPluginImpl(ReactIoCContainer, constructableMappings))

// HACK lbaglie: see useViewModel(ViewModelType)
ReactIoCContainer.bind(Navigator.SYMBOL).toDynamicValue(() => undefined)

export default ReactIoCContainer
