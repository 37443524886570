import Disposable from '../../utils/disposasble/Disposable'
import NotificationCenter from './NotificationCenter'
import NotificationType from './NotificationType'

export default class NotificationObserverDisposable implements Disposable {
  constructor(
    private center: NotificationCenter,
    private notification: NotificationType,
    private observer: any,
  ) {}

  dispose = () => this.center.removeObserver(this.notification, this.observer)
}
