import { Plugins } from "@capacitor/core";
import { injectable } from "inversify";
import LocalStoragePlugin from "./LocalStoragePlugin";

const { Storage } = Plugins;

const prefixKey = (key: string) => `CapacitorLocalStoragePlugin.${key}`;
const KEY_ALREADY_DONE = (actionName: string) =>
  prefixKey(`KEY_ALREADY_DONE.${actionName}`);

@injectable()
export default class CapacitorLocalStoragePlugin implements LocalStoragePlugin {
  hasAlreadyDone = (action: string) =>
    Storage.get({
      key: KEY_ALREADY_DONE(action),
    }).then(({ value }: { value: any }) => !!value);

  setAlreadyDone = (action: string) =>
    Storage.set({
      key: KEY_ALREADY_DONE(action),
      value: "true",
    });

  getString = (key: string) =>
    Storage.get({ key: prefixKey(key) }).then(
      ({ value }: { value: any }) => value || undefined
    );

  setString = (key: string, value: string) =>
    Storage.set({ key: prefixKey(key), value });

  // HACK lbaglie: storage.remove does not work :facepalm:
  remove = (key: string) => this.setString(key, "");
}
