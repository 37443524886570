import React, { useState } from "react";
import { InputChangeEventDetail } from "@ionic/core";
import PhoneInput from "react-phone-input-2";
import { Field } from "../field/Field";

// STYLES
import "react-phone-input-2/lib/style.css";
import "./international-phone-input.scss";
import StringsTypes from "../../plugins/localization/StringsTypes";

type InternationalPhoneInputProps = {
  country: string;
  label: string;
  className: string;
  error?: string;
  locales: StringsTypes;
  value: string;
  onChange: any; //(e: CustomEvent<InputChangeEventDetail>) => void;
  onChangeCountry: (code: string) => void;
};

export const ALLOWED_COUNTRIES: string[] = ["br", "us"];

const InternationalPhoneInput: React.FC<InternationalPhoneInputProps> = ({
  country,
  label,
  className,
  error,
  onChange,
  value,
}: InternationalPhoneInputProps) => {
  const [canShowError, setCanShowError] = useState(false);

  function onChangeInput(phone?: string) {
    if (phone) onChange({ detail: { value: phone } } as any);
  }

  return (
    <Field
      title={label}
      className={className}
      error={canShowError ? error : undefined}
    >
      <div className="internation-phone-input">
        <PhoneInput
          country={country}
          onlyCountries={ALLOWED_COUNTRIES}
          value={value}
          onChange={onChangeInput}
          onBlur={() => setCanShowError(true)}
        />
      </div>
    </Field>
  );
};

export default InternationalPhoneInput;
