import InjectableType from '../../dependencyInjection/InjectableType'
import SMSType from '../../plainTypes/phone/SMSType'

interface PhoneRepository {
  validatePhone(phone: string, smsType: SMSType): Promise<void>
  sendSMS(phone: string, smsType: SMSType): Promise<void>
  checkSMSCode(phone: string, code: string, smsType: SMSType): Promise<boolean>
}

const PhoneRepository: InjectableType = {
  SYMBOL: Symbol.for('PhoneRepository'),
}

export default PhoneRepository
