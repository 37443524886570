import LiveData, { LiveDataSetOptions } from './LiveData'

export default class LazyLiveDataImpl<T> implements LiveData<T> {
  private mInternal?: LiveData<T>

  private get internal() {
    if (!this.mInternal) {
      this.mInternal = this.lazyInit()
    }
    return this.mInternal
  }

  constructor(
    private readonly lazyInit: () => LiveData<T>,
  ) {}

  get value() {
    return this.internal.value
  }

  set = (value: T, options?: LiveDataSetOptions) => this.internal.set(value, options)

  observe = (observer: (newValue: T) => void) => this.internal.observe(observer)

  reset = () => this.internal.reset()

  dispose = () => this.internal.dispose()
}
