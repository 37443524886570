/* eslint-disable import/prefer-default-export */

export function keysOf<T>(obj: T): (keyof T)[] {
  return Object.keys(obj as object) as (keyof T)[];
}

export function valueOfEnumKey<T>(enumType: any, key: string): T {
  return enumType[key as unknown as any];
}

export const NULL_FUNCTION = () => {};
