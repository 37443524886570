import Disposable from './Disposable'

export default class CompositeDisposable implements Disposable {
  private observers: Set<Disposable>

  constructor(...observers: Disposable[]) {
    this.observers = new Set(observers)
  }

  add = (...observers: Disposable[]) => observers.forEach((o) => this.observers.add(o))

  dispose = () => {
    this.observers.forEach((o) => o.dispose())
    this.observers.clear()
  }
}
