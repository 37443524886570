import { IonFab, IonText } from '@ionic/react'
import React from 'react'
import useLocalization from '../../plugins/localization/useLocalization'
import ClickableDiv from '../clickable/ClickableDiv'
import './ContinueBottomButton.scss'


export interface ContinueBottomButtonProps {
  visible?: boolean
  title?: string
  onClick: () => void
}

const ContinueBottomButton: React.FC<ContinueBottomButtonProps> = ({
  visible, onClick, title,
}: ContinueBottomButtonProps) => {
  const { strings } = useLocalization()

  if (visible === false) {
    return null
  }

  return (
    <IonFab slot="fixed" vertical="bottom" horizontal="start" class="continue">
      <ClickableDiv onClick={onClick} className="continue__button">
        <IonText color="veryLight">{title ?? strings.continue}</IonText>
      </ClickableDiv>
    </IonFab>
  )
}

export default ContinueBottomButton
