import React, {
  useContext, useEffect, useReducer, useState,
} from 'react'
import { NULL_FUNCTION } from '../../utils/object'
import { guid } from '../../utils/random'

const SingletonsContext = React.createContext<
  [Map<string, string>, React.Dispatch<SingletonAction>]
>([new Map(), NULL_FUNCTION])

enum SingletonActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}

type SingletonAction = { type: SingletonActionType, key: string, owner: string }

export const SingletonsContextProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const reducer = useReducer((
    state: Map<string, string>, { type, key, owner }: SingletonAction,
  ) => {
    switch (type) {
      case SingletonActionType.ADD:
        return state.get(key) ? state : new Map(state).set(key, owner)

      case SingletonActionType.REMOVE: {
        if (state.get(key) !== owner) {
          return state
        }

        const newMap = new Map(state)
        newMap.delete(key)
        return newMap
      }

      default: throw new Error(`Unknown singleton action ${type}`)
    }
  }, new Map<string, string>())

  return (
    <SingletonsContext.Provider value={reducer}>
      {children}
    </SingletonsContext.Provider>
  )
}

export interface SingletonComponentProps {
  singletonKey: string
  children: () => JSX.Element
}

const SingletonComponent: React.FC<SingletonComponentProps> = ({
  children, singletonKey,
}: SingletonComponentProps) => {
  const [keys, dispatch] = useContext(SingletonsContext)
  const [id] = useState(guid())

  const currentOwner = keys.get(singletonKey)
  let canRender: boolean
  if (currentOwner) {
    canRender = currentOwner === id
  } else {
    canRender = true
  }

  useEffect(() => {
    if (canRender) {
      dispatch({ type: SingletonActionType.ADD, key: singletonKey, owner: id })
    }

    return () => dispatch({
      type: SingletonActionType.REMOVE, key: singletonKey, owner: id,
    })
  }, [canRender, dispatch, id, singletonKey])

  if (!canRender) {
    return <></>
  }

  return children()
}

export default SingletonComponent
