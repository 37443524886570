import InjectableType from '../../dependencyInjection/InjectableType'
import Disposable from '../../utils/disposasble/Disposable'
import NotificationType from './NotificationType'

export type NotificationObserver = (
  type: NotificationType, userInfo?: any
) => boolean | void

interface NotificationCenter {
  post(type: NotificationType, withUserInfo?: any): void
  observe(type: NotificationType, observer: NotificationObserver): Disposable
  removeObserver(type: NotificationType, observer: NotificationObserver): boolean
}

const NotificationCenter: InjectableType = {
  SYMBOL: Symbol.for('NotificationCenter'),
}

export default NotificationCenter
