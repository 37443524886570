import { isEqual, isNil, isString } from 'lodash'

export function contains(haystack: string | undefined | null, needle: string): boolean
export function contains<T>(
  haystack: T[] | undefined | null, needle: T | undefined | null,
): boolean
export function contains(
  haystack: any[] | string | undefined | null,
  needle: any,
): boolean {
  if (isNil(haystack)) {
    return false
  }

  return isString(haystack)
    ? haystack.indexOf(needle) > -1
    : haystack.findIndex((v) => isEqual(v, needle)) > -1
}

export function immutableReverse<T>(arr: T[]): T[] {
  return [...arr].reverse()
}

export function isBlank(arr: any[] | undefined | null): arr is undefined | null {
  return arr ? (arr.length === 0) : true
}

export async function mapAsync<T, TReturn>(
  array: T[], mapping: (value: T, index: number, array: T[]) => Promise<TReturn>,
): Promise<TReturn[]> {
  return Promise.all(array.map(mapping))
}

export function lastIndex(of: any[]): number {
  return of.length - 1
}
