import { LiveDataEx } from '../../liveData/LiveData'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import UndefinableLiveDataImpl from '../../liveData/UndefinableLiveDataImpl'
import SelectionController, { SelectionItem } from './SelectionController'

export default class SelectionControllerImpl<T> implements SelectionController<T> {
  readonly items = new LiveDataImpl<SelectionItem<T>[]>([])

  readonly selectedItem = new UndefinableLiveDataImpl<T>()

  protected initFun(items: T[]): void {
    this.items.set(items.map((item): SelectionItem<T> => ({ item })))
  }

  init = (items: T[]) => {
    this.initFun(items)
  }

  protected deinitFun(): void {
    LiveDataEx.disposeAllIn(this)
  }

  deinit = () => this.deinitFun()

  select = (idx: number) => {
    const items = this.items.value
    const newItems = [...items]

    if (this.selectedItem.hasValue) {
      const oldSelectedIdx = items.findIndex(({ selected }) => selected)
      newItems[oldSelectedIdx].selected = false
    }

    newItems[idx].selected = true
    this.items.set(newItems)
    this.selectedItem.set(newItems[idx].item)
  }

  selectItem = (item: T) => {
    const index = this.items.value.map((i) => i.item).indexOf(item)

    if (index < 0) {
      return false
    }

    this.select(index)
    return true
  }
}
