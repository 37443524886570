import { inject, injectable } from 'inversify'
import ContactorType from '../../plainTypes/dpo/ContactorType'
import Reason from '../../plainTypes/Reason'
import DPOService from '../../services/dpo/DPOService'
import { contains } from '../../utils/array'
import { isEmail } from '../../utils/string'
import DPORepository, {
  EmailError, PhoneError, PHONE_LENGTHS, TextError,
} from './DPORepository'

@injectable()
export default class DPORepositoryImpl implements DPORepository {
  @inject(DPOService.SYMBOL)
  private readonly service: DPOService

  getDPOReasons = (currentLanguage: string) => this.service.getDPOReasons(currentLanguage)

  requestDPO = (
    contactorType: ContactorType,
    name: string,
    email: string,
    isActive: boolean,
    phone: string,
    { id }: Reason,
    message: string,
    attachment?: File,
  ) => this.service.requestDPO(
    contactorType, name, email, isActive, phone, id, message, attachment,
  )

  validateName = (value: string) => {
    if (!value) {
      return TextError.EMPTY
    }

    if (value.trim().length < 3) {
      return TextError.TOO_SHORT
    }

    return undefined
  }

  validatePhone = (value: string) => {
    if (!value) {
      return PhoneError.EMPTY
    }

    const onlyNumbers = `+${value.replace(/[^(\d)]/g, '')}`
    if (onlyNumbers !== value || !contains(PHONE_LENGTHS, onlyNumbers.length)) {
      return PhoneError.INVALID
    }

    return undefined
  }

  validateEmail = (value: string) => {
    if (!value) {
      return EmailError.EMPTY
    }

    if (!isEmail(value)) {
      return EmailError.INVALID
    }

    return undefined
  }

  validateMessage = (value: string) => {
    if (!value) {
      return TextError.EMPTY
    }

    if (value.trim().length < 10) {
      return TextError.TOO_SHORT
    }

    return undefined
  }
}
