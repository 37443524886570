import InjectableType from '../../dependencyInjection/InjectableType'


interface LocalStoragePlugin {
  hasAlreadyDone(action: string): Promise<boolean>
  setAlreadyDone(action: string): Promise<void>

  getString(key: string): Promise<string | undefined>
  setString(key: string, value: string | undefined): Promise<void>

  remove(key: string): Promise<void>
}

const LocalStoragePlugin: InjectableType = {
  SYMBOL: Symbol.for('LocalStoragePlugin'),
}

export default LocalStoragePlugin
