import { isNil, negate } from 'lodash'

export function cutOff(
  str: string | undefined, length: number, ellipsis: string = '...',
): string | undefined {
  if (!str) {
    return str
  }

  return str.length > length
    ? str.substr(0, length) + ellipsis
    : str
}

export function isEmail(email: string | null | undefined) {
  if (!email) {
    return false
  }

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function concat(...strs: (string | undefined)[]): string {
  return strs.filter(negate(isNil)).join(' ')
}

export const CURRENT_LOCALE = window?.navigator?.languages[0] ??
  window?.navigator?.language ??
  'en-us'
