import InjectableType from '../../dependencyInjection/InjectableType'
import ContactorType from '../../plainTypes/dpo/ContactorType'
import Reason from '../../plainTypes/Reason'

interface DPOService {
  getDPOReasons(currentLanguage: string): Promise<Reason[]>
  requestDPO(
    contactorType: ContactorType,
    name: string,
    email: string,
    isActive: boolean,
    phone: string,
    reasonId: string,
    message: string,
    attachment?: File
  ): Promise<void>
}

const DPOService: InjectableType = {
  SYMBOL: Symbol.for('DPOService'),
}

export default DPOService
