import InjectableType from '../../dependencyInjection/InjectableType'
import ContactorType from '../../plainTypes/dpo/ContactorType'
import Reason from '../../plainTypes/Reason'

export enum TextError {
  EMPTY,
  TOO_SHORT
}

export const PHONE_LENGTHS = [13, 14]

export enum PhoneError {
  EMPTY,
  INVALID
}

export enum EmailError {
  EMPTY,
  INVALID
}

interface DPORepository {
  getDPOReasons(currentLanguage: string): Promise<Reason[]>
  requestDPO(
    contactorType: ContactorType,
    name: string,
    email: string,
    isActive: boolean,
    phone: string,
    reason: Reason,
    message: string,
    attachment?: File
  ): Promise<void>

  validateName(value: string): TextError | undefined
  validatePhone(value: string): PhoneError | undefined
  validateEmail(value: string): EmailError | undefined
  validateMessage(value: string): TextError | undefined
}

const DPORepository: InjectableType = {
  SYMBOL: Symbol.for('DPORepository'),
}

export default DPORepository
