import SelectionController from './SelectionController'
import SelectionControllerImpl from './SelectionControllerImpl'

export default class BoolSelectionControllerImpl implements SelectionController<boolean> {
  private readonly innerController = new SelectionControllerImpl<boolean>()

  readonly items = this.innerController.items

  readonly selectedItem = this.innerController.selectedItem

  init = () => this.innerController.init([true, false])

  deinit = this.innerController.deinit

  select = this.innerController.select

  selectItem = this.innerController.selectItem
}
