import { Subscription } from 'rxjs'
import Disposable from '../utils/disposasble/Disposable'

export default class LiveDataDisposable implements Disposable {
  constructor(private subscription: Subscription) {}

  dispose = () => {
    this.subscription.unsubscribe()
  }
}
