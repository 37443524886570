/* eslint-disable import/prefer-default-export */
import { isString } from 'lodash'
import { contains } from '../../../utils/array'
import when from '../../../utils/when'

export function translateOptions<T>(
  ...translations: Array<[T, string]>
): (val: T) => string;
export function translateOptions<T>(
  translations: Array<[T, string]>, fallback: string,
): (val: T) => string;
export function translateOptions<T>(...args: any[]): (val: T) => string {
  if (isString(args[1])) {
    const [translations, fallback] = args
    return (value: T) => when(value, translations.concat([value, fallback]))
  }

  return (value: T) => when(value, args.concat([value, 'ssssss']))
}

export function translateTemplate(template: string): (...args: any[]) => string {
  return (...args: any[]) => {
    let fixed = template
    args.forEach((arg, idx) => {
      const key = `{${idx}}`
      while (contains(fixed, key)) {
        fixed = fixed.replace(`{${idx}}`, arg)
      }
    })
    return fixed
  }
}
