export default function formatPhone(phone: string, showCountryCode: boolean = false): string {
  let formatted = showCountryCode ? phone.substr(0, 3) : ''

  if (phone.length > 3) {
    formatted += ` ${phone.substr(3, 2)}`

    if (phone.length > 5) {
      formatted += ` ${phone.substr(5, 5)}`

      if (phone.length > 10) {
        formatted += ` ${phone.substr(10)}`
      }
    }
  }

  return formatted.trim()
}

export function LENGTH_FORMATTED_PHONE(showCountryCode: boolean = false): number {
  return formatPhone('+5511123456789', showCountryCode).length
}
