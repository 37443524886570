import './Clickable.scss'

import React from 'react'
import { IonRippleEffect } from '@ionic/react'

export interface ClickableDivProps {
  className?: string
  onClick?: (args?: any) => any
}

const ClickableDiv: React.FC<React.PropsWithChildren<ClickableDivProps>> = ({
  children, className, onClick,
}: React.PropsWithChildren<ClickableDivProps>) => (
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
  <div
    className={`ion-activatable clickable ${className}`}
    role="button"
    onClick={onClick}
    onKeyDown={onClick}
  >
    {children}
    <IonRippleEffect />
  </div>
)

export default ClickableDiv
