import { injectable } from 'inversify'
import SMSType from '../../plainTypes/phone/SMSType'
import when from '../../utils/when'
import { runCloudCode } from '../utils/parse/cloudCode'
import PhoneService from './PhoneService'

@injectable()
export default class PhoneParseService implements PhoneService {
  validatePhone = (phone: string, smsType: SMSType) => runCloudCode<void>(
    'validatePhone', { phone, smsType },
  )

  sendSMS = (phone: string, smsType: SMSType) => runCloudCode<void>(
    when(smsType, [
      [SMSType.DPO_AUTHENTICATION, 'sendDpoAuthenticationSms'],
    ]),
    { phone },
  )
    .then((_) => undefined)

  checkSMSCode = (
    phone: string, verificationCode: string, smsType: SMSType,
  ) => runCloudCode<boolean>(
    when(smsType, [
      [SMSType.DPO_AUTHENTICATION, 'verifyDpoSmsCode'],
    ]),
    { phone, verificationCode },
  )
}
