import { inject, injectable } from 'inversify'
import { isNumber } from 'lodash'
import Navigator from '../navigation/Navigator'
import Localization from '../plugins/localization/Localization'
import AppError from './AppError'
import AppErrorCode from './AppErrorCode'
import ErrorParser from './ErrorParser'

@injectable()
export default class ErrorParserImpl implements ErrorParser {
  @inject(Localization.SYMBOL)
  private readonly localization: Localization

  @inject(Navigator.SYMBOL)
  private readonly navigator: Navigator

  parse = (error: any) => {
    console.error('Error', Object.entries(error))

    const { strings } = this.localization

    const fixdError = isNumber(error)
      ? new AppError(error)
      : error

    if (fixdError.code === undefined) {
      return new AppError(undefined, strings.error_generic)
    }

    let code: AppErrorCode
    let message: string
    try {
      code = fixdError.code as AppErrorCode
      message = strings.error_app_code_option(code)
    } catch (_ex) {
      code = AppErrorCode.UNKNOWN
      message = strings.error_generic
    }
    return new AppError(code, message)
  }
}
