import Axios from 'axios'
import { injectable } from 'inversify'
import { isNumber } from 'lodash'
import Configurations from '../../Configurations'
import AppError from '../../errors/AppError'
import ContactorType from '../../plainTypes/dpo/ContactorType'
import { combine } from '../../utils/path'
import { runCloudCode } from '../utils/parse/cloudCode'
import { decodeReason } from '../utils/reasonParsing'
import DPOService from './DPOService'

@injectable()
export default class DPOParseService implements DPOService {
  getDPOReasons = (currentLanguage: string) => runCloudCode<any[]>(
    'getDPOReasons',
    { language: currentLanguage },
  )
    .then((reasons) => reasons.map(decodeReason))

  requestDPO = async (
    contactorType: ContactorType,
    name: string,
    email: string,
    isActive: boolean,
    phone: string,
    reasonId: string,
    message: string,
    attachment?: File,
  ) => {
    const formData = new FormData()
    formData.append('contactorType', contactorType)
    formData.append('name', name)
    formData.append('email', email)
    formData.append('isActive', isActive.toString())
    formData.append('phone', phone)
    formData.append('reason', reasonId)
    formData.append('message', message)

    if (attachment) {
      formData.append('attachment', attachment)
    }

    await Axios.request({
      url: combine(Configurations.parse.HOST_URL, 'dpo/register-contact'),
      method: 'POST',
      data: formData,
      headers: {
        ...Configurations.parse.DEFAULT_HEADERS,
        'Content-Type': 'multipart/form-data',
      },
    })
      .catch((e) => {
        const code = e.response?.data?.code
        if (code && isNumber(code)) {
          throw new AppError(code)
        }

        throw e
      })
  }
}
