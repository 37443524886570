import { get } from 'lodash'
import { FormData, LiveFormDataEx } from '../plainTypes/FormData'
import { BindingFunction } from './bindings'
import LiveData from './LiveData'

export function bindFormDataToValue<T>(
  liveData: LiveData<FormData<T>>, value: T,
): BindingFunction {
  return () => LiveFormDataEx.setData(liveData, value)
}

export function bindFormDataToIonEventDetail<T>(
  liveData: LiveData<FormData<T>>,
): BindingFunction<CustomEvent<{ value: T | null | undefined }>> {
  return ({ detail: { value } }) => LiveFormDataEx.setData(liveData, value as T)
}

export function bindFormDataToFileInput(
  liveData: LiveData<FormData<File | undefined>>,
): BindingFunction<React.ChangeEvent<HTMLInputElement>> {
  return ({ currentTarget }) => LiveFormDataEx.setData(
    liveData, (get(currentTarget.files, 0) as File | null) ?? undefined,
  )
}
