import Parse from 'parse'
import Configurations from '../../../Configurations'

const ParseApiInitializer = {
  init: () => {
    const { parse } = Configurations
    Parse.initialize(parse.APP_ID)
    Parse.serverURL = parse.SERVER_URL
  },
}

export default ParseApiInitializer
