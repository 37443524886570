import LiveDataImpl from './LiveDataImpl'
import UndefinableLiveData from './UndefinableLiveData'

export default class UndefinableLiveDataImpl<T> extends LiveDataImpl<T | undefined>
  implements UndefinableLiveData<T> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(initial?: T) {
    super(initial)
  }

  get value(): T | undefined {
    return this.mValue
  }

  get hasValue(): boolean {
    return this.mValue !== undefined
  }
}
