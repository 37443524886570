/* eslint-disable lines-between-class-members */
/* eslint-disable camelcase */

import AppErrorCode from '../../../errors/AppErrorCode'
import ContactorType from '../../../plainTypes/dpo/ContactorType'
import FileFormat, { FileFormatEx } from '../../../plainTypes/FileFormat'
import { EmailError, PhoneError, TextError } from '../../../repositories/dpo/DPORepository'
import { translateOptions, translateTemplate } from './utils'

export default class LocalizedStrings {
  // common
  app_name = 'ysos'
  ok = 'OK'
  yes = 'Sim'
  no = 'Não'
  save = 'Salvar'
  cancel = 'Cancelar'
  close = 'Fechar'
  signup = 'Cadastrar'
  enter = 'Entrar'
  continue = 'Continuar'
  attention = 'Atenção'
  confirm = 'Confirmar'
  alter = 'Alterar'
  back = 'Voltar'
  you = 'você'
  understood = 'Entendi'
  format_date = 'DD/MM/YYYY'
  sexualorientation = 'Orientação Sexual'
  password = 'Senha'
  dismiss = 'Descartar'
  select = 'Selecionar'
  delete = 'Excluir'
  send = 'Enviar'
  default_country_code = '+55'
  mask_phone = '+55 99 99999 9999'
  template_field_file_maxsize = (size: number, unit: 'mb' = 'mb') => `Tamanho max. ${size}${unit}.`
  template_field_file_formats = (formats: FileFormat[]) => `Arquivos suportados ${
    formats.map(FileFormatEx.fileExtension).join(' ')
  }`
  placeholder_field_file = 'Toque para selecionar um arquivo'

  // enums
  options_boolean_yesno = translateOptions(
    [true, 'Sim'],
    [false, 'Não'],
  )
  options_contactor_type = translateOptions(
    [ContactorType.AUTHORITY, 'Autoridade'],
    [ContactorType.CONSUMER_PROTECTION_AGENCY, 'Orgão de Proteção ao Consumidor'],
    [ContactorType.HOLDER, 'Titular'],
  )

  // dpo
  title_dpo = 'Solicitar dados'
  message_dpo_reasons = 'Fale com o nosso encarregado (DPO) sob a Lei Geral de Proteção de Dados (LGPD) para solicitar mais informações sobre seus dados no app.'
  label_dpo_form_contactor = 'Você é*'
  label_dpo_form_name = 'Nome*'
  label_dpo_form_email = 'E-mail*'
  label_dpo_form_active = 'Está ativo na plataforma*'
  label_dpo_form_phone = 'Número de celular*'
  label_dpo_form_reason = 'Qual o assunto do requerimento*'
  label_dpo_form_message = 'Descreva em detalhe sua solicitação*'
  label_dpo_form_attachment = 'Enviar anexo (optional)'
  dialog_title_dpo_sent = 'Mensagem enviada!'
  dialog_message_dpo_sent = 'Nosso DPO entrará em contato com você em breve, pelo e-mail.'
  options_error_text = translateOptions(
    [TextError.EMPTY, 'Texto vazio'],
    [TextError.TOO_SHORT, 'Texto muito curto'],
  )
  options_error_phone = translateOptions(
    [PhoneError.EMPTY, 'Telefone vazio'],
    [PhoneError.INVALID, 'Telefone inválido'],
  )
  options_error_email = translateOptions(
    [EmailError.EMPTY, 'Email vazio'],
    [EmailError.INVALID, 'Email inválido'],
  )
  template_dialog_message_sms_phone = translateTemplate('Seu número de celular está escrito corretamente?{0}')
  template_dialog_title_sms_code = translateTemplate(
    'Insira o código enviado por SMS para {0}',
  )
  dialog_message_sms_code = 'Acesse sua caixa de mensagens do celular e digite o código enviado'
  dialog_button_sms_code = 'Validar Código'
  dialog_button_sms_resend = 'Reenviar Código'
  dialog_message_sms_invalid = 'Código digitado incorretamente ou expirado'
  dialog_title_sms_valid = 'Pronto'
  dialog_message_sms_valid = 'Seu número foi verificado\nAgora a solicitação será enviada.'
  dialog_button_sms_support_wait = 'Aguardar mais um pouco'
  dialog_button_sms_support_request = 'Entre em contato conosco'

  // support
  subject_support_sms = '[ysos app] Não recebo o SMS de cadastro'
  template_body_support_sms = translateTemplate('Meu celular é: {0}')

  // error
  error_generic = 'Ops! Ocorreu um erro.\nTente novamente ou entre em contato com o suporte.'
  template_error_field_file_maxsize = (size: number, unit: 'mb' = 'mb') => `O anexo excede o limite de ${size}${unit}. Reduza o tamanho do arquivo.`
  template_error_field_file_formats = (formats: FileFormat[]) => `Formato de anexo não permitido. Use apenas arquivos ${
    formats.map(FileFormatEx.fileExtension).join(' ')
  }`
  error_app_code_option = translateOptions([
    [AppErrorCode.APP_INVALID_CREDENTIALS, 'Login ou senha inválidos. Tente novamente!'],
    [AppErrorCode.APP_PERMISSION, 'O aplicativo não possui permissão para realizar a ação.'],
    [AppErrorCode.APP_NO_ACCOUNT, 'É necessário configurar uma conta de email para enviar emails ao ysos.'],
    [AppErrorCode.APP_TIMEOUT, 'Tempo limite estourado. Verifique conexão com internet.'],
    [AppErrorCode.APP_PERMISSION_DENIED_CAMERA, 'É necessário liberar permissão para a câmera no aplicativo Ajustes.'],
    [AppErrorCode.APP_COULDNT_CONNECT_STORE, 'Compra cancelada.'],
    [AppErrorCode.APP_COULDNT_CONNECT_STORE_RESTORE, 'Restauração cancelada.'],
    [AppErrorCode.CODE_TWILIO_INVALID_PHONE_NUMBER, ''],
    [AppErrorCode.CODE_TWILIO_UNVERIFIED_PHONE_NUMBER, ''],
    [AppErrorCode.OBJECT_NOT_FOUND, 'Objeto não encontrado.'],
    [AppErrorCode.USERNAME_MISSING, 'O nome de perfil não foi preenchido! Tente novamente ou entre em contato com o suporte.'],
    [AppErrorCode.PASSWORD_MISSING, 'A senha não foi preenchida! Tente novamente ou entre em contato com o suporte.'],
    [AppErrorCode.USERNAME_TAKEN, 'Já existe um cadastro com esse nome de perfil! Por favor, volte no cadastro de nome de perfil e escolha outro :('],
    [AppErrorCode.SESSION_MISSING, ''],
    [AppErrorCode.INVALID_USER, ''],
    [AppErrorCode.CODE_PHONE_ALREADY_REGISTERED, 'Ops! Número já cadastrado.'],
    [AppErrorCode.NOT_ALLOWED_TO_SEND_SIGNUP_SMS, 'Ops! O envio do seu código já foi solicitado. O recebimento do SMS pode demorar algumas horas.'],
    [AppErrorCode.CODE_PHONE_NOT_REGISTERED, 'Ops! Seu número não está cadastrado.'],
    [AppErrorCode.INVALID_SMS_VERIFICATION_CODE, 'Código digitado incorretamente ou expirado :('],
    [AppErrorCode.NOT_ALLOWED_TO_SEND_FORGOT_PASSWORD_SMS, 'Ops! O envio do seu código já foi solicitado. O recebimento do SMS pode demorar algumas horas.'],
    [AppErrorCode.PHONE_BLACKLISTED, ''],
    [AppErrorCode.USER_IS_NOT_BLOCKED, ''],
    [AppErrorCode.USER_IS_BLOCKED, 'O usuário não está mais disponível.'],
    [AppErrorCode.NO_CHAT_OPEN_FOR_IDS, ''],
    [AppErrorCode.NO_CHAT_USER_IDS, ''],
    [AppErrorCode.CANT_BLOCK_YSOS_USER, 'Não é possível bloquear o usuário ysos.'],
    [AppErrorCode.FAILED_TO_CONNECT, 'Não foi possível conectar ao ysos. Tente novamente mais tarde.'],
    [AppErrorCode.PROFILE_ALREADY_FILLED, 'O perfil já está preenchido'],
    [AppErrorCode.INVALID_PARAMETERS, 'Ocorreu um erro interno. Contate o suporte.'],
    [AppErrorCode.NO_LIKE_FOUND, 'A curtida não foi encontrada.'],
    [AppErrorCode.DEPLETED_LIKES, 'Suas curtidas diárias terminaram. Aguarde 24hs para continuar a curtir.'],
    [AppErrorCode.SUBSCRIPTION_EXPIRED, 'Não há assinaturas disponíveis no momento.'],
    [AppErrorCode.ASSINET_INTERNAL_ERROR, 'Falha no processamento. Por favor entre em contato com o suporte.'],
    [AppErrorCode.SMS_LIMIT_REACHED, 'Problemas para receber o SMS?\nEntre em contato com o suporte.'],
    [AppErrorCode.ACCESS_DENIED, 'Seu perfil foi temporariamente bloqueado! Entre em contato com o suporte para saber mais.'],
    [AppErrorCode.ATTACHMENT_FORMAT_UNALLOWED, 'Formato de anexo não permitido. Aceita-se somente arquivos .png, .jpg, .pdf, .doc e .docx.'],
    [AppErrorCode.ATTACHMENT_SIZE_LIMIT, 'O arquivo excedeu o limite. Reduza o tamanho do arquivo.'],
  ], 'Ops! Ocorreu um erro.\nTente novamente ou entre em contato com o suporte.')
}
