/* eslint-disable import/prefer-default-export */
import Parse from 'parse'
import AppErrorCode from '../../../errors/AppErrorCode'

// lbaglie: gotta be handled here, because parse won't return on timeout (WTF).
const DEFAULT_TIMEOUT_MILLIS = 60 * 1000

async function internalRunCloudeCode<T>(
  name: string, params: Object,
  preFlight: () => Promise<Parse.Cloud.RunOptions> = async () => ({}),
): Promise<T> {
  return new Promise((resolve, reject) => {
    let returned = false
    let timeoutToken: NodeJS.Timeout
    function callBack(action: () => void): void {
      if (!returned) {
        action()
        returned = true
        clearTimeout(timeoutToken)
      }
    }

    preFlight()
      .then((options) => Parse.Cloud.run(name, params, options))
      .then((result) => callBack(() => resolve(result)))
      .catch((ex) => callBack(() => reject(ex)))

    timeoutToken = setTimeout(
      () => {
        callBack(() => reject(AppErrorCode.APP_TIMEOUT))
      },
      DEFAULT_TIMEOUT_MILLIS,
    )
  })
}

export async function runUserCloudCode<T = any>(name: string, params: Object = {}): Promise<T> {
  return internalRunCloudeCode(name, params)
}

export function runCloudCode<T = any>(name: string, params: Object = {}): Promise<T> {
  return internalRunCloudeCode(name, params)
}
