import { Subject } from 'rxjs'
import Trigger from './Trigger'
import LiveDataDisposable from './LiveDataDisposable'

export default class TriggerImpl implements Trigger {
  private subject = new Subject<void>()

  fire = () => this.subject.next(undefined)

  observe = (observer: (_value: void) => void) => {
    if (this.subject.isStopped) {
      this.subject = new Subject()
    }
    const disposable = this.subject.subscribe(observer)
    return new LiveDataDisposable(disposable)
  }

  dispose = () => {
    this.subject.unsubscribe()
  }
}
