import React from 'react'
import { IonToast } from '@ionic/react'

export enum ToastDuration {
  SHORT = 1800,
  LONG = 2800
}

export interface ErrorToastProps {
  isOpen: boolean
  message: string
  duration: ToastDuration
  onDidDismiss: Function
}

const ErrorToast: React.FC<ErrorToastProps> = ({
  isOpen, message, duration, onDidDismiss,
}: ErrorToastProps) => (
  <IonToast
    isOpen={isOpen}
    onDidDismiss={() => onDidDismiss()}
    message={message}
    duration={duration}
  />
)

export default ErrorToast
